import React from "react";
import { useEffect } from "react";
import ReactFlow, { useNodesState, useEdgesState, Controls } from "reactflow";
import "./ProcessAdminPage.scss";

const ProcessSteps = (processConfig) => {
  const stepsData = processConfig.processConfig.attributes["process-config"];
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  useEffect(() => {
    (async () => {
      if (stepsData) {
        stepsData?.nodes?.forEach((elem) => {
          const newNode = {
            id: elem.id,
            position: elem.position,
            targetPosition: "left",
            sourcePosition: "right",
            data: {
              name: elem.data.name,
              description: elem.data.description,
              label: (
                <div className="node-box">
                  <div
                    className="node-up"
                    style={{
                      backgroundColor: "#F4E4FF",
                      borderRadius: "3px",
                    }}
                  >
                    <div
                      className="nameText"
                      title={elem.data.name}
                      style={{ color: "#8A00E5", fontWeight: "bold" }}
                    >
                      {elem.data.name}
                    </div>
                  </div>
                  <div className="node-down">
                    <p
                      className="processDescText m-0"
                      title={elem.data.description}
                    >
                      {elem.data.description}
                    </p>
                  </div>
                </div>
              ),
            },
          };
          setNodes((nds) => nds.concat(newNode));
        });

        setEdges(stepsData?.edges);
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="layoutflow d-flex w-100">
      <div className="react-flow-section w-100 border">
        <div className="flow-canvas">
          <ReactFlow
            nodes={nodes}
            edges={edges}
            deleteKeyCode="Delete"
            draggable={true}
            nodesDraggable={true}
            fitView
          >
            <Controls />
          </ReactFlow>{" "}
        </div>
      </div>
    </div>
  );
};

export default ProcessSteps;
