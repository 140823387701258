import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { getHostUrl } from "../../config/environment";
import { getData } from "../../services/apiService";
import { patchData } from "../../services/apiService";
import Loader from "../Loader/Loader";

const GroupModal = (props) => {
  const defaultNodeColor = "#8a00e5";
  const defaultFontColor = "#FFFFFF";
  const [formValues, setFormValues] = useState([]);
  // eslint-disable-next-line
  const [fontColor, setFontColor] = useState(defaultFontColor);
  // eslint-disable-next-line
  const [nodeColor, setNodeColor] = useState(defaultNodeColor);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const fetchGroupList = async () => {
      setLoading(true);
      try {
        const groupLink = `${getHostUrl()}process/groups`;
        const res = await getData(groupLink);
        if (res.status === 200) {
          const formattedValues = res.data.data.map((group, index) => ({
            id: group.id,
            title: group.attributes.title,
            nodeColor: group.attributes["node-color"] || defaultNodeColor,
            fontColor: group.attributes["font-color"] || defaultFontColor,
            isDefault: group.attributes["is-default"],
          }));
          const sortedValues = [...formattedValues].sort((a, b) => {
            if (a.isDefault && !b.isDefault) return -1;
            if (!a.isDefault && b.isDefault) return 1;
            return 0;
          });

          setFormValues(sortedValues);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching group list", error);
      }
    };

    fetchGroupList();
  }, []);

  const handleChange = (i, e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => {
      const newFormValues = [...prevValues];
      newFormValues[i][name] = value;
      return newFormValues;
    });
  };

  const addFormFields = () => {
    setFormValues((prevValues) => [
      ...prevValues,
      {
        id: prevValues.length
          ? Math.max(...prevValues.map((item) => item.id)) + 1
          : 1,
        title: "",
        nodeColor,
        fontColor,
        isDefault: false,
      },
    ]);
  };

  const removeFormFields = (i) => {
    setFormValues((prevValues) => {
      const newFormValues = prevValues.filter((_, index) => index !== i);
      return newFormValues;
    });
  };

  const handleNodeColorChange = (index, color) => {
    setFormValues((prevValues) => {
      const newFormValues = [...prevValues];
      newFormValues[index].nodeColor = color.hex;
      return newFormValues;
    });
  };

  const handleFontColorChange = (index, color) => {
    setFormValues((prevValues) => {
      const newFormValues = [...prevValues];
      newFormValues[index].fontColor = color.hex;
      return newFormValues;
    });
  };

  const handleSave = async () => {
    setLoad(true);
    const updatedGroupList = formValues.map((elem) => ({
      type: "process-groups",
      attributes: {
        title: elem.title,
        "node-color": elem.nodeColor,
        "font-color": elem.fontColor,
      },
      id: elem.id?.toString(),
    }));

    try {
      const res = await patchData(`${getHostUrl()}process/groups`, {
        data: updatedGroupList,
      });
      if (res.status === 200) {
        setLoad(false);
        props.onHide();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Manage Process Groups
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="trainings-links-modal">
        {loading ? (
          <div style={{ maxWidth: "100%", height: "13vh" }}>
            <Loader />
          </div>
        ) : (
          <Form>
            {formValues.map((elem, index) => (
              <Row className="mb-3" key={elem.id}>
                <Form.Group as={Col} controlId={`formGroupName${index}`}>
                  <Form.Label className="w-100">
                    <span className="w-100 d-flex justify-content-between">
                      <p className="mb-0">Group Name</p>
                      {!elem.isDefault && (
                        <p
                          className="app-tertiary-btn"
                          onClick={() => removeFormFields(index)}
                        >
                          Remove
                        </p>
                      )}
                    </span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Group Name"
                    name="title"
                    value={elem.title}
                    onChange={(e) => handleChange(index, e)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required*
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} controlId={`formGroupNodeColor${index}`}>
                  <Form.Label>Group Node Color (HEX)</Form.Label>
                  <div
                    className="border d-flex justify-content-between"
                    style={{
                      height: "40px",
                      alignItems: "center",
                      padding: "5px 5px",
                    }}
                  >
                    <div>{elem.nodeColor}</div>
                    <input
                      type="color"
                      value={elem.nodeColor}
                      onChange={(e) =>
                        handleNodeColorChange(index, { hex: e.target.value })
                      }
                    />
                  </div>
                </Form.Group>

                <Form.Group as={Col} controlId={`formGroupFontColor${index}`}>
                  <Form.Label>Group Font Color (HEX)</Form.Label>
                  <div
                    className="border d-flex justify-content-between"
                    style={{
                      height: "40px",
                      alignItems: "center",
                      padding: "5px 5px",
                    }}
                  >
                    <div>{elem.fontColor}</div>
                    <input
                      type="color"
                      value={elem.fontColor}
                      onChange={(e) =>
                        handleFontColorChange(index, { hex: e.target.value })
                      }
                    />
                  </div>
                </Form.Group>
              </Row>
            ))}
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <div>
            <Button className="app-primary-btn me-2" onClick={addFormFields}>
              Add
            </Button>
          </div>
          <div>
            <Button className="app-secondary-btn me-2" onClick={props.onHide}>
              Close
            </Button>
            <Button className="app-primary-btn" onClick={() => handleSave()}>
              {load ? "Saving..." : "Save"}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default GroupModal;
