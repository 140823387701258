import { ListGroupItem } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

export const ProcessOrgDetailModal = (props) => {
  const listData = props.orgData;

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="user-modal my-auto"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="user-modal-title"
        >
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.heading}
        <ListGroup>
          {listData.map((value) => {
            return (
              <Link
                style={{ textDecoration: "none" }}
                to={`/landscape/organization?id=${value.id}`}
                state={{ prevPath: window.location.href }}
              >
                <ListGroupItem className="org-list-item">
                  {value.title}
                </ListGroupItem>
              </Link>
            );
          })}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};
