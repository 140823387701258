import React from "react";
import { useEffect, useState, useCallback } from "react";
import { Form, Button } from "react-bootstrap";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Controls,
  MarkerType,
} from "reactflow";
import { addProcess, updateProcessData } from "../../utils/processUtil";
import "../AppAdminComponents/AppStyles.scss";

const AddProcessSteps = ({ setPageLevel, setFlag, pageLevel }) => {
  const processData = JSON.parse(sessionStorage.getItem("processPayload"));

  const processConfig = sessionStorage.getItem("processConfig")
    ? JSON.parse(sessionStorage.getItem("processConfig"))
    : null;

  let id = processConfig ? processConfig?.nodes.length + 1 : 1;
  const getId = () => `${id++}`;
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [loading, setLoading] = useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    (async () => {
      if (processConfig) {
        setNodes([]);
        processConfig?.nodes?.forEach((elem) => {
          const newNode = {
            id: elem.id,
            position: elem.position,
            targetPosition: "top",
            sourcePosition: "bottom",
            data: {
              name: elem.data.name,
              description: elem.data.description,
              label: (
                <div className="node-box">
                  <div
                    className="node-up"
                    style={{
                      backgroundColor: "#F4E4FF",
                      borderRadius: "3px",
                    }}
                  >
                    <div
                      className="nameText"
                      title={elem.data.name}
                      style={{ color: "#8A00E5", fontWeight: "bold" }}
                    >
                      {elem.data.name}
                    </div>
                  </div>
                  <div className="node-down">
                    <p
                      className="processDescText m-0"
                      title={elem.data.description}
                    >
                      {elem.data.description}
                    </p>
                  </div>
                </div>
              ),
            },
          };
          setNodes((nds) => nds.concat(newNode));
        });

        setEdges(processConfig?.edges);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const addNode = useCallback((props) => {
    const id = getId();
    const newNode = {
      id,
      position: { x: 0, y: 0 },
      targetPosition: "top",
      sourcePosition: "bottom",
      data: {
        name: props.name,
        description: props.desc,
        label: (
          <div className="node-box">
            <div
              className="node-up"
              style={{
                backgroundColor: "#F4E4FF",
                borderRadius: "3px",
              }}
            >
              <div
                className="nameText"
                title={props.name}
                style={{ color: "#8A00E5", fontWeight: "bold" }}
              >
                {props.name}
              </div>
            </div>
            <div className="node-down">
              <p className="processDescText m-0" title={props.desc}>
                {props.desc}
              </p>
            </div>
          </div>
        ),
      },
    };
    setNodes((nds) => nds.concat(newNode));
    setName("");
    setDesc("");

    // eslint-disable-next-line
  }, []);

  const addEdge = useCallback(({ source, target }) => {
    setEdges((eds) =>
      eds.concat({
        id: Math.random(),
        type: "step",
        source,
        target,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 15,
          height: 15,
          color: "#8A00E5",
        },
        style: {
          strokeWidth: 1,
          stroke: "#8A00E5",
        },
      })
    );
    // eslint-disable-next-line
  }, []);

  return (
    <div className="d-flex flex-column">
      <div className="mb-3">
        <h4 className="fw-bold">
          {processData && processData?.data?.attributes?.title}
        </h4>
        <h6>{processData && processData?.data?.attributes?.desc}</h6>
      </div>
      <div className="layers-section d-flex w-100">
        <div className="list-section w-25 me-3 border">
          <span className="select-tool mx-auto">
            <p className="fw-bold mt-3 mb-2 fs-5">Add Process Steps</p>
            <div className="app-primary-input-field mt-2 mx-auto">
              <Form className="d-flex flex-column border-0 p-0">
                <Form.Group
                  className="mb-3 w-100"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>
                    <b>Enter Title:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Add Title"
                    value={name}
                    name="tool_description"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3 w-100"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>
                    <b>Enter Description:</b>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Add Description"
                    value={desc}
                    name="tool_description"
                    rows={4}
                    onChange={(e) => {
                      setDesc(e.target.value);
                    }}
                  />
                </Form.Group>
              </Form>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                className="app-primary-btn"
                disabled={name?.length > 0 ? false : true}
                onClick={() => {
                  addNode({
                    name: name && name,
                    desc: desc && desc,
                  });
                }}
              >
                Add Step
              </Button>
            </div>
          </span>
        </div>
        <div className="react-flow-section w-75 border">
          <div className="flow-canvas">
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onConnect={addEdge}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              deleteKeyCode="Delete"
              draggable={false}
              nodesDraggable={true}
            >
              <Controls />
            </ReactFlow>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end w-100 mt-3">
        <Button
          className="app-secondary-btn my-auto me-2"
          onClick={() => {
            sessionStorage.setItem(
              "processConfig",
              JSON.stringify({ nodes: nodes && nodes, edges: edges && edges })
            );
            pageLevel === "addSteps"
              ? setPageLevel("addProcess")
              : setPageLevel("editProcess");
          }}
        >
          Cancel
        </Button>
        <Button
          className="app-primary-btn"
          onClick={() => {
            setLoading(true);
            if (pageLevel === "addSteps") {
              addProcess({
                processData,
                processConfig:
                  nodes?.length > 0 || edges?.length > 0
                    ? {
                        nodes: nodes && nodes,
                        edges: edges && edges,
                      }
                    : null,
                setPageLevel: setPageLevel,
                setFlag: setFlag,
                setLoading: setLoading,
              });
            } else {
              updateProcessData({
                processData,
                processConfig:
                  nodes?.length > 0 || edges?.length > 0
                    ? {
                        nodes: nodes && nodes,
                        edges: edges && edges,
                      }
                    : null,
                setPageLevel: setPageLevel,
                setFlag: setFlag,
                setLoading: setLoading,
              });
            }
          }}
        >
          {pageLevel === "addSteps" && !loading
            ? "Create Process"
            : pageLevel === "addSteps" && loading
            ? "Creating Process..."
            : pageLevel === "editSteps" && !loading
            ? "Update Process"
            : pageLevel === "editSteps" && loading
            ? "Updating Process..."
            : "Create Process"}
        </Button>
      </div>
    </div>
  );
};

export default AddProcessSteps;
