import { React, useState, useEffect } from "react";
import ResponsivePagination from "react-responsive-pagination";
import Loader from "../../Loader/Loader";
import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Nav,
} from "react-bootstrap";
import { getHostUrl } from "../../../config/environment";
import { getData } from "../../../services/apiService";
import { Typeahead } from "react-bootstrap-typeahead";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import { getBaseUrl } from "../../../config/environment";
import Avatar from "react-avatar";
import { useNavigate, useLocation } from "react-router-dom";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Button } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import ProcessDetailsPage from "./ProcessDetailPage";
import { UserModal } from "../../../utils/processUtil";
import { MultipleSelectDropdown } from "../../CommonComponents/MultipleSelect";
import ImageSvgs from "../../ImageSvgs";
import ProcessNetworkGraph from "./ProcessNetworkGraph";
import CustomTooltip from "../../CommonComponents/CustomTooltip";
import { ProcessOrgDetailModal } from "./ProcessOrgDetailModal";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../../../../src/assets/scss/common.scss";
import "../ProcessAdminPage.scss";

export default function ProcessUpdatedPage() {
  const [processData, setProcessData] = useState([]);
  const [included, setIncluded] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allLoading, setAllLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [show, setShow] = useState(false);
  const [showEmpModal, setShowEmpModal] = useState(false);
  const [activePro, setActivePro] = useState();
  const [usersData, setUsersData] = useState([]);
  const [query, setQuery] = useState("");
  const [activeBtn, setActiveBtn] = useState("cardView");
  const [dropdownShow, setDropdownShow] = useState(false);
  const [orgList, setOrgList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [orgQuery, setOrgQuery] = useState();
  const [groupQuery, setGroupQuery] = useState();
  const [finalOrgQuery, setFinalOrgQuery] = useState();
  const [finalGrpQuery, setFinalGrpQuery] = useState();
  const [deleted, setIsDeleted] = useState(false);
  const [showOrgModal, setShowOrgModal] = useState(false);
  const [orgValues, setOrgValues] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const adminCheck = sessionStorage.getItem("adminCheck");

  useEffect(() => {
    debouncedGetResponse();
    return debouncedGetResponse.cancel;
    // eslint-disable-next-line
  }, [currentPage, query, activeBtn, finalGrpQuery, finalOrgQuery, deleted]);

  useEffect(() => {
    setCurrentPage(currentPage);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const processId = urlParams.get("process_id");
    if (processId && processId !== "0") {
      setActivePro(processId);
      setShow(true);
    } else {
      setActivePro("0");
      setShow(false);
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location?.search);
    const viewParam = queryParams.get("view");
    if (viewParam === "mapView") {
      setActiveBtn("mapView");
    } else {
      setActiveBtn("cardView");
    }
  }, [location.search]);

  const processRes = async () => {
    setLoading(true);

    const queryParams = new URLSearchParams();

    const itemsPerPage = 20;

    if (activeBtn === "mapView") {
      if (query || finalGrpQuery?.length > 0 || finalOrgQuery?.length > 0) {
        queryParams.set("template", "process_search");
      } else {
        queryParams.set("template", "process_map_view");
      }
      queryParams.set("include", "child_processes,parent_processes,orgs");
      queryParams.set("use_pagination", "false");
    } else if (activeBtn === "cardView") {
      if (query || finalGrpQuery?.length > 0 || finalOrgQuery?.length > 0) {
        queryParams.set("template", "process_search");
      } else {
        queryParams.set("template", "process_card_view");
      }
      queryParams.set("include", "owners,orgs");
      queryParams.set("use_pagination", "true");
      queryParams.set("page", currentPage.toString());
      queryParams.set("per_page", itemsPerPage.toString());
    } else if (query) {
      queryParams.set("template", "process_search");
    } else {
      queryParams.set("include", "owners,orgs");
      queryParams.set("use_pagination", "true");
      queryParams.set("page", currentPage.toString());
    }

    if (finalGrpQuery?.length > 0) {
      queryParams.set("group", finalGrpQuery[0]?.id.toString());
    }
    if (finalOrgQuery?.length > 0) {
      queryParams.set("org_id", finalOrgQuery[0]?.id.toString());
    }

    if (query) {
      queryParams.set("search", query);
    }

    const pageParam =
      activeBtn === "cardView" && query ? `&page=${currentPage}` : "";

    const endpoint =
      query || finalGrpQuery?.length > 0 || finalOrgQuery?.length > 0
        ? `${getHostUrl()}process/search?${queryParams.toString()}`
        : `${getHostUrl()}process?${queryParams.toString()}${pageParam}`;

    try {
      const res = await getData(endpoint);

      if (res?.status === 200) {
        if (activeBtn === "mapView") {
          setProcessData(res.data.data);
          setIncluded(res.data.included);
        } else {
          setProcessData(res.data.data);
          setIncluded(res.data.included);
          setTotalPage(res.data.meta["page-count"]);
          setCurrentPage(res.data.meta.page);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        const groupEndpoint = `${getHostUrl()}process/groups`;
        const grpResponse = await getData(groupEndpoint);
        if (grpResponse?.status === 200) {
          setGroupList(grpResponse.data.data);
          sessionStorage.setItem(
            "allProcessGroups",
            JSON.stringify(grpResponse.data.data)
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchGroupData();
  }, []);

  useEffect(() => {
    const fetchProcessData = async () => {
      setAllLoading(true);
      const community = `${getHostUrl()}community?use_pagination=false`;
      const training = `${getHostUrl()}training?use_pagination=false`;
      const application = `${getHostUrl()}application?use_pagination=false`;
      const orgData = `${getHostUrl()}organization/search?search=SE FIN GS&use_pagination=false`;

      try {
        const [res3, res4, res5, res6] = await Promise.all([
          getData(community),
          getData(training),
          getData(application),
          getData(orgData),
        ]);

        sessionStorage.setItem("communityAll", JSON.stringify(res3.data));
        sessionStorage.setItem("trainingAll", JSON.stringify(res4.data));
        sessionStorage.setItem("applicationAll", JSON.stringify(res5.data));
        sessionStorage.setItem("orgAll", JSON.stringify(res6.data));
      } catch (err) {
        if (err.response?.status === 401) {
          console.error("Unauthorized access:", err);
        } else {
        }
      } finally {
        setAllLoading(false);
      }
    };

    fetchProcessData();
  }, []);

  const debouncedGetResponse = debounce(processRes, 400);

  const handleProcessData = (value) => {
    setActivePro(value);
    setShow(true);
  };

  const setUsers = (list) => {
    let userList = [];
    // eslint-disable-next-line
    list.map((e) => {
      let newElem = included?.filter(
        (item) =>
          item.type === "owners" && item.id.toString() === e.id.toString()
      )[0];
      userList.push(newElem);
    });
    return userList;
  };

  const getOrgTitles = (orgIds) => {
    return orgIds.map((orgId) => {
      const org = included.find((org) => org.id === orgId);

      return {
        id: org.attributes["org-id"],
        title: org?.attributes?.["org-master"]?.data?.attributes?.["org-title"],
      };
    });
  };

  const renderProcesses = (processDetails) => {
    return (
      <>
        <div className="grid-container ">
          {processDetails?.length > 0 ? (
            <div className="w-100 d-flex flex-wrap flex-row">
              {processDetails?.map((value) => {
                const isActive =
                  value.id?.toString() === activePro?.id?.toString();

                const orgIds = value.relationships.orgs.data.map(
                  (org) => org.id
                );
                const orgTitles = getOrgTitles(orgIds);

                const handleProcessClick = (e, value) => {
                  e.stopPropagation();
                  handleProcessData(value.id);
                  navigate(
                    `/landscape/process?process_id=${value.id}&view=${activeBtn}`,
                    { state: { prevPath: window.location.href } }
                  );
                };

                const handleOrgModalClick = (e) => {
                  e.stopPropagation();
                  setShowOrgModal(true);
                  setOrgValues(orgTitles);
                };

                const handleEmployeeModalClick = async (e) => {
                  e.stopPropagation();
                  let userList = await setUsers(
                    value.relationships.owners.data
                  );
                  setUsersData(userList);
                  setShowEmpModal(true);
                };

                return (
                  <div
                    key={value.id}
                    className={`process-cards grid-item ${
                      isActive ? "highlighted" : "not-highlighted"
                    }`}
                    onClick={(e) => {
                      handleProcessClick(e, value);
                    }}
                  >
                    <div style={{ height: "2.5rem" }}>
                      <div style={{ minHeight: "3rem" }}>
                        <Col className="d-flex flex-row justify-content-between">
                          <div className="w-75">
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-title`}>
                                  {value.attributes.title}
                                </Tooltip>
                              }
                            >
                              <div className="process-title">
                                {value.attributes.title}
                              </div>
                            </OverlayTrigger>
                          </div>
                        </Col>
                      </div>
                    </div>

                    <div
                      className="d-flex flex-row org-container"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="org-title">
                        <Link
                          style={{
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                          className="org-title-link"
                          to={`/landscape/organization?id=${orgTitles[0]?.id}`}
                          state={{ prevPath: window.location.href }}
                        >
                          {orgTitles?.length > 0 ? orgTitles[0]?.title : "N/A"}
                        </Link>
                      </div>
                      {orgTitles?.length > 1 && (
                        <button
                          className="more-user-btn"
                          onClick={(e) => {
                            handleOrgModalClick(e);
                          }}
                        >
                          {` +${orgTitles.length - 1} more`}
                        </button>
                      )}
                    </div>
                    <Col className="pt-1 pb-1">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-title`}>
                            {value.attributes.desc}
                          </Tooltip>
                        }
                      >
                        <div className="process-desc">
                          {value.attributes.desc}
                        </div>
                      </OverlayTrigger>
                    </Col>

                    <div className="d-flex flex-row justify-content-between">
                      <div
                        onClick={(e) => {
                          handleEmployeeModalClick(e);
                        }}
                      >
                        <span>
                          {value?.relationships?.owners?.data?.length > 0 ? (
                            <>
                              {value?.relationships?.owners?.data
                                .slice(0, 3)
                                .map((elem, index) => {
                                  let owner = included?.filter(
                                    (item) =>
                                      item.type === "owners" &&
                                      item.id.toString() === elem.id.toString()
                                  )[0];

                                  return (
                                    <span
                                      key={index}
                                      className="me-1"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(
                                          `/employee_relation?id=${owner?.attributes["employee-id"]}`
                                        );
                                      }}
                                    >
                                      {owner?.attributes?.employee?.data
                                        ?.attributes["profile-picture"] ? (
                                        <CustomTooltip
                                          data={owner}
                                          name={
                                            owner?.attributes?.employee?.data
                                              ?.attributes["preferred-name"]
                                          }
                                          orgCode={
                                            owner.attributes.employee.data
                                              .attributes["employee-org-master"]
                                              .data.attributes["org-title"]
                                          }
                                        >
                                          <img
                                            className="my-auto"
                                            src={`${getBaseUrl()}${
                                              owner?.attributes?.employee?.data
                                                ?.attributes["profile-picture"]
                                            }`}
                                            style={{
                                              height: "2rem",
                                              width: "2rem",
                                              border: "1.5px solid black",
                                              borderRadius: "7rem",
                                              objectFit: "contain",
                                            }}
                                            alt="User Img"
                                          />
                                        </CustomTooltip>
                                      ) : (
                                        <CustomTooltip
                                          data={owner}
                                          name={
                                            owner?.attributes?.employee?.data
                                              ?.attributes["preferred-name"]
                                          }
                                          orgCode={
                                            owner?.attributes?.employee?.data
                                              ?.attributes[
                                              "employee-org-master"
                                            ].data.attributes["org-title"]
                                          }
                                        >
                                          <Avatar
                                            name={
                                              owner?.attributes?.employee?.data
                                                ?.attributes["preferred-name"]
                                            }
                                            size="30"
                                            round={true}
                                          />
                                        </CustomTooltip>
                                      )}
                                    </span>
                                  );
                                })}
                              {value?.relationships?.owners?.data?.length >
                              3 ? (
                                <button
                                  className="more-user-btn"
                                  onClick={(e) => {
                                    handleEmployeeModalClick(e);
                                  }}
                                >
                                  +{" "}
                                  {value?.relationships?.owners?.data?.length -
                                    3}{" "}
                                  more
                                </button>
                              ) : null}
                            </>
                          ) : (
                            <b>-</b>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              className="d-flex justify-content-center "
              style={{ paddingTop: "7rem" }}
            >
              <div>
                <ImageSvgs name="no_data" />
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const handleOrgSearch = async (query) => {
    const param = `${getHostUrl()}organization/search?search=${query}&org_chart_flag=${true}&per_page=20`;
    const res = await getData(param);
    setOrgList(res?.data?.data);
  };
  const handleGroupSearch = (value) => {
    setGroupQuery(value);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleInputChange = (e) => {
    setCurrentPage(1);
    if (e.target.value) {
      setQuery(e.target.value);
    } else {
      setQuery("");
    }
  };

  const handleButtonClick = (buttonId) => {
    if (buttonId === "cardView") {
      setCurrentPage(1);
    }
    setActiveBtn(buttonId);
    navigate(`/landscape/process?id=0&view=${buttonId}`);
  };

  const handleApply = () => {
    setFinalOrgQuery(orgQuery);
    setFinalGrpQuery(groupQuery);
    setDropdownShow(false);
  };

  const handleCancel = () => {
    // setOrgQuery([]);
    // setGroupQuery([]);
    // setFinalOrgQuery([]);
    // setFinalGrpQuery([]);
    setDropdownShow(false);
  };

  return (
    <div className="process-user-page">
      <div className="process-content">
        <Container fluid className="mx-0 me-0 main-process-container">
          <Row>
            <Col xs={6} xl={6} className="d-flex justify-content-start w-50">
              <ButtonGroup className="my-2 w-50">
                <Button
                  className={
                    activeBtn === "cardView"
                      ? "form-select-btn form-select-activeBtn d-flex flex-row justify-content-center"
                      : "form-select-btn d-flex flex-row justify-content-center"
                  }
                  style={{ width: "50%" }}
                  onClick={() => handleButtonClick("cardView")}
                >
                  <div>Card View</div>
                  <div className="mx-2">
                    {activeBtn === "cardView" ? (
                      <ImageSvgs name="cardView" />
                    ) : (
                      <ImageSvgs name="cardViewDisable" />
                    )}
                  </div>
                </Button>

                <Button
                  className={
                    activeBtn === "mapView"
                      ? "form-select-btn form-select-activeBtn d-flex flex-row justify-content-center"
                      : "form-select-btn d-flex flex-row justify-content-center"
                  }
                  style={{ width: "50%" }}
                  onClick={() => handleButtonClick("mapView")}
                >
                  <div>Map View</div>
                  <div className="mx-2">
                    {activeBtn === "mapView" ? (
                      <ImageSvgs name="mapView" />
                    ) : (
                      <ImageSvgs name="mapViewDisable" />
                    )}
                  </div>
                </Button>
              </ButtonGroup>
            </Col>

            <Col xs={6} xl={6} className="d-flex justify-content-end">
              <div className="d-flex pt-1">
                {adminCheck && adminCheck === "true" ? (
                  <Nav.Link
                    href="/manage_process?page=addProcess"
                    className="my-auto"
                  >
                    <Button
                      disabled={allLoading}
                      onClick={() => {
                        sessionStorage.setItem("processFlag", "true");
                      }}
                      className="app-tertiary-btn"
                    >
                      Add Process
                    </Button>
                  </Nav.Link>
                ) : null}
                <div
                  className="app-primary-input-field pt-1 ms-3"
                  style={{ width: "15rem" }}
                >
                  <input
                    type="search"
                    id="message"
                    value={query}
                    placeholder="Search Process..."
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <Dropdown
                show={dropdownShow}
                onSelect={() => setDropdownShow(true)}
                className="custom-dropdown-main"
              >
                <Dropdown.Toggle
                  onClick={() => setDropdownShow(true)}
                  id="dropdown-basic"
                  className="dropdown-btn pe-0"
                >
                  <ImageSvgs name="filterLogo" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100 custom-dropdown">
                  <div className="dropdown-title"> Advanced Filters</div>
                  <div className="pt-2 pb-2">
                    <div className="pb-1 pt-1">Groups</div>
                    <div className="app-primary-dropdown">
                      <MultipleSelectDropdown
                        multi={false}
                        color="#8A00E5"
                        placeholder="Search by Groups"
                        className="dropdown-select mb-2"
                        options={
                          groupList?.length > 0 &&
                          [...groupList]
                            .sort(
                              (a, b) =>
                                a.attributes["title"].split(" ").length -
                                1 -
                                (b.attributes["title"].split(" ").length - 1)
                            )
                            ?.map((grp) => ({
                              type: "process-groups",
                              name: grp.attributes["title"],
                              id: grp.id.toString(),
                            }))
                        }
                        onChange={handleGroupSearch}
                        values={groupQuery}
                      />
                    </div>
                  </div>

                  <div className="pt-2 pb-2">
                    <div className="pb-1 pt-1">Org Code</div>
                    <div>
                      <span style={{ display: "block", width: "100%" }}>
                        <div className="app-primary-dropdown mb-3">
                          <Typeahead
                            labelKey="name"
                            filterBy={["name"]}
                            placeholder="Search by OrgCode"
                            id="typeAhead"
                            highlightOnlyResult={true}
                            clearButton={true}
                            onChange={async (selected) => {
                              if (selected?.length > 0) {
                                setOrgQuery(selected);
                              }
                            }}
                            onInputChange={(text) => {
                              handleOrgSearch(text);
                              setOrgQuery(text);
                            }}
                            options={
                              orgQuery?.length < 4
                                ? []
                                : orgList &&
                                  orgList.map((item) => ({
                                    type: "organization",
                                    name: item.attributes["org-title"],
                                    id: item.id,
                                  }))
                            }
                          />
                        </div>
                      </span>
                    </div>
                  </div>

                  <div>
                    <div className="d-flex justify-content-end font-weight-bold pt-4">
                      <Button
                        className="app-secondary-btn ms-3 my-auto"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>

                      <Button
                        className="app-primary-btn ms-3"
                        onClick={handleApply}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Container>

        {!loading ? (
          <div
            className="pt-1 main-div"
            style={{
              paddingLeft: "0.7rem",
              paddingRight: "0.7rem",
              minHeight: "47rem",
            }}
          >
            {activeBtn === "cardView" ? (
              renderProcesses(processData)
            ) : (
              <div
                className="border"
                style={{ marginRight: "1.3rem", marginLeft: "1.3rem" }}
              >
                <ProcessNetworkGraph
                  data={processData}
                  included={included}
                  groupList={groupList}
                  view={activeBtn}
                />
              </div>
            )}
          </div>
        ) : (
          <div style={{ maxWidth: "100%", height: "47rem" }}>
            <Loader />
          </div>
        )}

        <div
          className="w-100 mt-3  pb-2  d-flex justify-content-end"
          style={{ paddingRight: "2rem" }}
        >
          {activeBtn === "cardView" ? (
            <ResponsivePagination
              current={currentPage}
              total={totalPage}
              onPageChange={handlePageChange}
            />
          ) : null}
        </div>
        <ProcessDetailsPage
          hide={handleClose}
          show={show}
          activeData={activePro}
          activeBtn={activeBtn}
          included={included}
          deleted={deleted}
          setIsDeleted={setIsDeleted}
          allLoading={allLoading}
        />
      </div>
      {showEmpModal === true ? (
        <UserModal
          title={"Process Owners and Deputies"}
          listData={usersData}
          show={showEmpModal}
          onHide={() => setShowEmpModal(false)}
        />
      ) : (
        ""
      )}
      {showOrgModal === true ? (
        <ProcessOrgDetailModal
          show={showOrgModal}
          title={"List of Org Codes"}
          onHide={() => setShowOrgModal(false)}
          orgData={orgValues}
        />
      ) : (
        ""
      )}
    </div>
  );
}
