import { React, useState, useEffect } from "react";
import Graph from "react-graph-vis";
import ProcessDetailsPage from "./ProcessDetailPage";
import { useNavigate } from "react-router-dom";
import "./ProcessNetworkGraph.scss";
import "vis-network/styles/vis-network.min.css";

export default function ProcessNetworkGraph(props) {
  const [showDetail, setShowDetail] = useState(false);
  // eslint-disable-next-line
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [groupOptions, setGroupOptions] = useState({});
  const navigate = useNavigate();

  function formatNodeLabel({ title, orgTitles, group }) {
    const groupLabel = group && group !== "Default" ? group : "N/A";
    return `${title}\n\n<code>${groupLabel}</code>`;
  }

  const orgTitleMap = props?.included?.reduce((acc, item) => {
    if (item.type === "orgs") {
      const orgId = item.id;

      const orgTitle =
        item.attributes["org-master"].data.attributes["org-title"];

      if (orgId && orgTitle) {
        acc[orgId] = orgTitle;
      }
    }
    return acc;
  }, {});

  const generateGroupOptions = (groupData) => {
    const groups = groupData.reduce((acc, group) => {
      const {
        title,
        "node-color": color,
        "font-color": fontColor,
      } = group.attributes;

      acc[title] = {
        color: {
          background: color || "#FFFFFF",
          border: color || "#8a00e5",
        },
        font: {
          color: fontColor ? fontColor : "",
        },
      };

      return acc;
    }, {});

    return groups;
  };

  useEffect(() => {
    setGroupOptions(generateGroupOptions(props.groupList));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const nodeMap = new Map();
    const newEdges = [];

    props.data.forEach((process) => {
      const orgIds = process.relationships.orgs.data.map((org) => org.id);
      const orgTitles = orgIds.map((orgId) => orgTitleMap[orgId] || null);

      const node = {
        id: process.id.toString(),
        label: formatNodeLabel({
          title: process.attributes.title,
          orgTitles: orgTitles,
          group:
            process.attributes["process-group"]?.data?.attributes.title ||
            "Default",
          nodeColor:
            process.attributes["process-group"]?.data?.attributes[
              "node-color"
            ] || "#CCCCCC",
          fontColor:
            process.attributes["process-group"]?.data?.attributes[
              "font-color"
            ] || "#CCCCCC",
        }),
        shape: "box",
        group:
          process.attributes["process-group"]?.data?.attributes.title ||
          "Default",
      };

      nodeMap.set(node.id, node);

      if (process?.relationships?.["parent-processes"]?.data.length > 0) {
        process.relationships["parent-processes"].data.forEach(
          (processDataItem) => {
            const includedData = props.included.find(
              (data) => data.id === processDataItem.id
            );

            if (includedData) {
              const fromId = includedData.attributes["child-id"];
              const toId = includedData.attributes["parent-id"];

              newEdges.push({
                from: fromId,
                to: toId,
                smooth: { enabled: true },
              });

              const parentProcess = props.data.find(
                (p) => p.id === parseInt(toId)
              );
              if (
                parentProcess &&
                parentProcess.relationships["parent-processes"].data.includes(
                  parseInt(fromId)
                )
              ) {
                newEdges.push({
                  from: toId,
                  to: fromId,
                  smooth: { enabled: true },
                });
              }
            }
          }
        );
      }
    });

    const updatedNodes = Array.from(nodeMap.values());

    setNodes(updatedNodes);
    setEdges(newEdges);
    // eslint-disable-next-line
  }, [props.data, props.included]);

  const handleClose = () => {
    setShowDetail(false);
  };
  const graph = {
    nodes: nodes,
    edges: edges,
  };

  const options = {
    interaction: { hover: true },
    autoResize: true,
    nodes: {
      shape: "box",
      size: 600,

      borderWidth: 3,
      font: {
        multi: true,
        size: 16,
        background: "none",
        align: "left",
      },
      chosen: {
        node: function (values, id, selected, hovering) {
          if (selected) {
            values.borderWidth = 4;
            values.borderColor = values.color;
            values.font = {
              multi: true,
              size: 16,
              background: "none",
              align: "left",
              bold: true,
            };
          } else {
            values.borderWidth = 3;
          }
        },
      },
    },
    groups: groupOptions,

    edges: {
      color: { color: "grey", hover: "black" },
      width: 2,
    },

    height: "700px",

    physics: {
      enabled: true,
      solver: "forceAtlas2Based",
      forceAtlas2Based: {
        gravitationalConstant: -600,
        centralGravity: 0.04,
        springConstant: 0.01,
        damping: 4,
        avoidOverlap: 1,
      },
      stabilization: {
        iterations: 100,
        updateInterval: 25,
        onlyDynamicEdges: false,
        fit: true,
      },
    },

    layout: {
      improvedLayout: true,
      hierarchical: {
        enabled: false,
      },
    },
  };

  const filterDataById = (id) => {
    return (
      props.data.find((item) => item.id.toString() === id.toString()) || null
    );
  };

  const events = {
    select: function (event) {
      // eslint-disable-next-line
      var { nodes, edges } = event;
    },
    click: function (event) {
      var { nodes, edges } = event;

      if (nodes.length > 0) {
        const processId = nodes[0];
        const filteredData = filterDataById(processId);
        setSelectedProcess(filteredData);
        navigate(
          `/landscape/process?process_id=${processId}&view=${props.view}`
        );
        setShowDetail(true);
      } else if (edges.length > 0) {
      }
    },
  };

  return (
    <>
      <Graph
        graph={graph}
        options={options}
        events={events}
        getNetwork={(network) => {}}
      />

      {showDetail === true ? (
        <ProcessDetailsPage
          hide={handleClose}
          show={showDetail}
          handleClose={handleClose}
          activeBtn={props.view}
          included={props.included}
        />
      ) : null}
    </>
  );
}
