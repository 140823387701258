import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import ImageSvgs from "../ImageSvgs";
import { Nav } from "react-bootstrap";
import "../../assets/scss/common.scss";
import { Button } from "react-bootstrap";
import AddNewTool from "./AddNewTool";
import { getData } from "../../services/apiService";
import { getHostUrl, getBaseUrl } from "../../config/environment";
import { DeleteToolModal } from "../../utils/toolsUtils";
import Avatar from "react-avatar";
import CustomTooltip from "../CommonComponents/CustomTooltip";
import { UserModal } from "../../utils/toolsUtils";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./ManageTools.scss";

const layerList = [
  { id: 1, name: "UI" },
  { id: 2, name: "Integration" },
  { id: 3, name: "Source" },
  { id: 4, name: "Not in Focus" },
];

const ToolsTable = ({ tool, data }) => {
  const iniTool = tool;
  const iniToolData = data["data"];
  const [toolLevel, setToolLevel] = useState("tool");
  const [toolData, setToolData] = useState(data["data"]);
  const [trainingData, setTrainingData] = useState(null);
  const [communityData, setCommunityData] = useState(null);
  const [employeeData, setEmployeeData] = useState(null);
  const [editToolData, setEditToolData] = useState();
  const [res, setGetRes] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const navigate = useNavigate();
  const [usersData, setUsersData] = useState();
  const [flag, setFlag] = useState(false);
  const [experts, setExperts] = useState({});
  const [deleteToolData, setDeleteToolData] = useState();
  // eslint-disable-next-line
  const [included, setIncluded] = useState(data.included);

  const [nameQuery, setNameQuery] = useState("");
  const [descQuery, setDescQuery] = useState("");
  const [typeQuery, setTypeQuery] = useState([]);

  if (flag) {
    const param = `${getHostUrl()}tools?include=apps,trainings,experts,communities,tool_info&use_pagination=false`;
    const expertToolMap = {};
    const emp = `${getHostUrl()}employee`;
    (async () => {
      const res = await getData(param);
      const empRes = await getData(emp);
      await setToolData(res.data.data);
      await setIncluded(res.data["included"]);
      await setEmployeeData(empRes.data.data);
      res.data["included"]?.forEach((item) => {
        if (item.type === "experts") {
          const tool_id = item.attributes["tool-id"];
          if (expertToolMap[tool_id]) {
            expertToolMap[tool_id].push(item);
          } else {
            expertToolMap[tool_id] = [item];
          }
        }
      });
      await setExperts(expertToolMap);
    })();

    setFlag(false);
  }

  const applicationName = {};
  // eslint-disable-next-line
  toolData?.map((app) => {
    app.relationships.apps.data?.length > 0 &&
      // eslint-disable-next-line
      app.relationships.apps.data.map((item) => {
        data.included.forEach((appVal) => {
          if (appVal.type === "application") {
            const app_id = appVal.id;
            if (applicationName[app_id]) {
              applicationName[app_id].push(appVal);
            } else {
              applicationName[app_id] = [appVal];
            }
          }
        });
      });
  });

  const community = `${getHostUrl()}community?use_pagination=false`;
  const training = `${getHostUrl()}training?use_pagination=false`;
  const powerUsers = `${getHostUrl()}employee`;

  async function getResponse() {
    const res = await getData(community);
    const res1 = await getData(training);
    const res2 = await getData(powerUsers);
    try {
      if (res) {
        setGetRes(true);
        setEmployeeData(res2.data.data);
        setTrainingData(res1.data.data);
        setCommunityData(res.data.data);
      }
    } catch (err) {
      if (err) {
        setGetRes(false);
        if (err.response.status === 401) {
          setGetRes(true);
        }
      }
    }
  }

  useEffect(() => {
    const expertToolMap = {};
    data["included"]?.forEach((item) => {
      if (item.type === "experts") {
        const tool_id = item["attributes"]["tool-id"];
        if (expertToolMap[tool_id]) {
          expertToolMap[tool_id].push(item);
        } else {
          expertToolMap[tool_id] = [item];
        }
      }
    });
    setExperts(expertToolMap);
    getResponse();
    // eslint-disable-next-line
  }, [res]);

  useEffect(() => {
    const url = new URL(window.location.href);
    setToolLevel(url.searchParams.get("page"));
  }, [iniTool]);

  const addQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, "", url.toString());
  };

  const navigateToPage = () => {
    const url = new URL(window.location.href);
    url.searchParams.get("page");
    if (url.searchParams.get("page") === "tool") {
      navigate("/manage_users");
    } else {
      setToolLevel("tool");
      addQueryParam("page", "tool");
    }
  };
  const nameSearch = (query) => {
    if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      typeQuery.length.toString() === "0"
    ) {
      setToolData(iniToolData);
    } else if (
      query.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      typeQuery.length.toString() === "0"
    ) {
      setToolData(
        iniToolData?.filter((item) =>
          item.attributes["tool-name"].toLowerCase().includes(query)
        )
      );
    } else if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      typeQuery.length.toString() === "0"
    ) {
      setToolData(
        iniToolData?.filter((item) =>
          item.attributes["tool-description"]
            .toLowerCase()
            .includes(descQuery.toLowerCase())
        )
      );
    } else if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      typeQuery.length.toString() !== "0"
    ) {
      let tempList = [];
      iniToolData?.map((elem) => {
        if (
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].includes(typeQuery["0"].id)
        ) {
          tempList.push(elem);
        } else if (
          typeQuery["0"].id.toString() === "4" &&
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].length.toString() === "0"
        ) {
          tempList.push(elem);
        }
        return false;
      });
      setToolData(tempList);
    } else if (
      query.length.toString() !== "0" &&
      descQuery?.length.toString() !== "0" &&
      typeQuery.length.toString() === "0"
    ) {
      setToolData(
        iniToolData?.filter(
          (item) =>
            item.attributes["tool-name"].toLowerCase().includes(query) &&
            item.attributes["tool-description"]
              .toLowerCase()
              .includes(descQuery.toLowerCase())
        )
      );
    } else if (
      query.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      typeQuery.length.toString() !== "0"
    ) {
      let tempList1 = iniToolData?.filter((item) =>
        item.attributes["tool-description"]
          .toLowerCase()
          .includes(descQuery.toLowerCase())
      );
      let tempList2 = [];
      iniToolData?.map((elem) => {
        if (
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].includes(typeQuery["0"].id)
        ) {
          tempList2.push(elem);
        } else if (
          typeQuery["0"].id.toString() === "4" &&
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].length.toString() === "0"
        ) {
          tempList2.push(elem);
        }
        return false;
      });
      setToolData(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes["tool-name"] === elem2.attributes["tool-name"]
          )
        )
      );
    } else if (
      query.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      typeQuery.length.toString() !== "0"
    ) {
      let tempList1 = iniToolData?.filter((item) =>
        item.attributes["tool-name"].toLowerCase().includes(query)
      );
      let tempList2 = [];
      iniToolData?.map((elem) => {
        if (
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].includes(typeQuery["0"].id)
        ) {
          tempList2.push(elem);
        } else if (
          typeQuery["0"].id.toString() === "4" &&
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].length.toString() === "0"
        ) {
          tempList2.push(elem);
        }
        return false;
      });

      setToolData(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes["tool-name"] === elem2.attributes["tool-name"]
          )
        )
      );
    } else if (
      query.length.toString() > "0" &&
      descQuery?.length.toString() > "0" &&
      typeQuery.length.toString() > "0"
    ) {
      let tempList = [];
      iniToolData?.map((elem) => {
        if (
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].includes(typeQuery["0"].id)
        ) {
          tempList.push(elem);
        } else if (
          typeQuery["0"].id.toString() === "4" &&
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].length.toString() === "0"
        ) {
          tempList.push(elem);
        }
        return false;
      });

      let arr1 = tempList.concat(
        iniToolData?.filter((item) =>
          item.attributes["tool-name"].toLowerCase().includes(query)
        ),
        iniToolData?.filter((item) =>
          item.attributes["tool-description"]
            .toLowerCase()
            .includes(descQuery.toLowerCase())
        )
      );

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(
            iniToolData?.filter((item) => item.id === elem.id)["0"]
          );
        }
        return false;
      });

      setToolData(finalList);
    }
  };

  const descSearch = (query) => {
    if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() === "0" &&
      typeQuery.length.toString() === "0"
    ) {
      setToolData(iniToolData);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query?.length.toString() === "0" &&
      typeQuery.length.toString() === "0"
    ) {
      setToolData(
        iniToolData?.filter((item) =>
          item.attributes["tool-name"]
            .toLowerCase()
            .includes(nameQuery.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() !== "0" &&
      typeQuery.length.toString() === "0"
    ) {
      setToolData(
        iniToolData?.filter((item) =>
          item.attributes["tool-description"]
            .toLowerCase()
            .includes(query.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() === "0" &&
      typeQuery.length.toString() !== "0"
    ) {
      let tempList = [];
      iniToolData?.map((elem) => {
        if (
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].includes(typeQuery["0"].id)
        ) {
          tempList.push(elem);
        } else if (
          typeQuery["0"].id.toString() === "4" &&
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].length.toString() === "0"
        ) {
          tempList.push(elem);
        }
        return false;
      });
      setToolData(tempList);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query?.length.toString() !== "0" &&
      typeQuery.length.toString() === "0"
    ) {
      setToolData(
        iniToolData?.filter(
          (item) =>
            item.attributes["tool-name"]
              .toLowerCase()
              .includes(nameQuery.toLowerCase()) &&
            item.attributes["tool-description"]
              .toLowerCase()
              .includes(query.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      query?.length.toString() !== "0" &&
      typeQuery.length.toString() !== "0"
    ) {
      let tempList1 = iniToolData?.filter((item) =>
        item.attributes["tool-description"]
          .toLowerCase()
          .includes(query.toLowerCase())
      );
      let tempList2 = [];
      iniToolData?.map((elem) => {
        if (
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].includes(typeQuery["0"].id)
        ) {
          tempList2.push(elem);
        } else if (
          typeQuery["0"].id.toString() === "4" &&
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].length.toString() === "0"
        ) {
          tempList2.push(elem);
        }
        return false;
      });
      setToolData(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes["tool-name"] === elem2.attributes["tool-name"]
          )
        )
      );
    } else if (
      nameQuery?.length.toString() !== "0" &&
      query?.length.toString() === "0" &&
      typeQuery.length.toString() !== "0"
    ) {
      let tempList1 = iniToolData?.filter((item) =>
        item.attributes["tool-name"]
          .toLowerCase()
          .includes(nameQuery.toLowerCase())
      );
      let tempList2 = [];
      iniToolData?.map((elem) => {
        if (
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].includes(typeQuery["0"].id)
        ) {
          tempList2.push(elem);
        } else if (
          typeQuery["0"].id.toString() === "4" &&
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].length.toString() === "0"
        ) {
          tempList2.push(elem);
        }
        return false;
      });

      setToolData(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes["tool-name"] === elem2.attributes["tool-name"]
          )
        )
      );
    } else if (
      nameQuery?.length.toString() > "0" &&
      query?.length.toString() > "0" &&
      typeQuery.length.toString() > "0"
    ) {
      let tempList = [];
      iniToolData?.map((elem) => {
        if (
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].includes(typeQuery["0"].id)
        ) {
          tempList.push(elem);
        } else if (
          typeQuery["0"].id.toString() === "4" &&
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].length.toString() === "0"
        ) {
          tempList.push(elem);
        }
        return false;
      });

      let arr1 = tempList.concat(
        iniToolData?.filter((item) =>
          item.attributes["tool-name"]
            .toLowerCase()
            .includes(nameQuery.toLowerCase())
        ),
        iniToolData?.filter((item) =>
          item.attributes["tool-description"]
            .toLowerCase()
            .includes(query.toLowerCase())
        )
      );

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(
            iniToolData?.filter((item) => item.id === elem.id)["0"]
          );
        }
        return false;
      });

      setToolData(finalList);
    }
  };

  const typeSearch = (value) => {
    setTypeQuery(value);
    if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      value.length.toString() === "0"
    ) {
      setToolData(iniToolData);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      value.length.toString() === "0"
    ) {
      setToolData(
        iniToolData?.filter((item) =>
          item.attributes["tool-name"]
            .toLowerCase()
            .includes(nameQuery.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      value.length.toString() === "0"
    ) {
      setToolData(
        iniToolData?.filter((item) =>
          item.attributes["tool-description"]
            .toLowerCase()
            .includes(descQuery.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() === "0" &&
      value.length.toString() !== "0"
    ) {
      let tempList = [];
      iniToolData?.map((elem) => {
        if (
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].includes(value["0"].id)
        ) {
          tempList.push(elem);
        } else if (
          value["0"].id.toString() === "4" &&
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].length.toString() === "0"
        ) {
          tempList.push(elem);
        }
        return false;
      });
      setToolData(tempList);
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() !== "0" &&
      value.length.toString() === "0"
    ) {
      setToolData(
        iniToolData?.filter(
          (item) =>
            item.attributes["tool-name"]
              .toLowerCase()
              .includes(nameQuery.toLowerCase()) &&
            item.attributes["tool-description"]
              .toLowerCase()
              .includes(descQuery.toLowerCase())
        )
      );
    } else if (
      nameQuery?.length.toString() === "0" &&
      descQuery?.length.toString() !== "0" &&
      value.length.toString() !== "0"
    ) {
      let tempList1 = iniToolData?.filter((item) =>
        item.attributes["tool-description"]
          .toLowerCase()
          .includes(descQuery.toLowerCase())
      );
      let tempList2 = [];
      iniToolData?.map((elem) => {
        if (
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].includes(value["0"].id)
        ) {
          tempList2.push(elem);
        } else if (
          value["0"].id.toString() === "4" &&
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].length.toString() === "0"
        ) {
          tempList2.push(elem);
        }
        return false;
      });
      setToolData(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes["tool-name"] === elem2.attributes["tool-name"]
          )
        )
      );
    } else if (
      nameQuery?.length.toString() !== "0" &&
      descQuery?.length.toString() === "0" &&
      value.length.toString() !== "0"
    ) {
      let tempList1 = iniToolData?.filter((item) =>
        item.attributes["tool-name"]
          .toLowerCase()
          .includes(nameQuery.toLowerCase())
      );
      let tempList2 = [];
      iniToolData?.map((elem) => {
        if (
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].includes(value["0"].id)
        ) {
          tempList2.push(elem);
        } else if (
          value["0"].id.toString() === "4" &&
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].length.toString() === "0"
        ) {
          tempList2.push(elem);
        }
        return false;
      });

      setToolData(
        tempList1.filter((elem1) =>
          tempList2.some(
            (elem2) =>
              elem1.id === elem2.id &&
              elem1.attributes["tool-name"] === elem2.attributes["tool-name"]
          )
        )
      );
    } else if (
      nameQuery?.length.toString() > "0" &&
      descQuery?.length.toString() > "0" &&
      value.length.toString() > "0"
    ) {
      let tempList = [];
      iniToolData?.map((elem) => {
        if (
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].includes(value["0"].id)
        ) {
          tempList.push(elem);
        } else if (
          value["0"].id.toString() === "4" &&
          [
            ...new Set(
              elem["attributes"]["nodes"]["data"].map(
                (item) => item["attributes"]["layer-id"]
              )
            ),
          ].length.toString() === "0"
        ) {
          tempList.push(elem);
        }
        return false;
      });

      let arr1 = tempList.concat(
        iniToolData?.filter((item) =>
          item.attributes["tool-name"]
            .toLowerCase()
            .includes(nameQuery.toLowerCase())
        ),
        iniToolData?.filter((item) =>
          item.attributes["tool-description"]
            .toLowerCase()
            .includes(descQuery.toLowerCase())
        )
      );

      let arr2 = [];
      let key = "id";

      arr1.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] === x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      let finalList = [];

      arr2.map((elem) => {
        if (elem.occurrence.toString() === "3") {
          finalList.push(
            iniToolData?.filter((item) => item.id === elem.id)["0"]
          );
        }
        return false;
      });

      setToolData(finalList);
    }
  };

  return (
    <>
      <div>
        <div className="back-icon d-flex">
          <button
            className="fw-bold border-0 bg-transparent"
            onClick={() => navigateToPage()}
          >
            {" "}
            <ImageSvgs name="goBack" />
            <span className="navigate-back"> Back </span>
          </button>
        </div>
        <>
          {toolLevel === "tool" ? (
            <>
              <div className="sub-bar d-flex justify-content-between">
                <p className="main-title">
                  Tools({toolData && toolData.length})
                </p>
                <div className="search-div d-flexm ">
                  <Button
                    className="app-secondary-btn"
                    onClick={() => {
                      setToolLevel("addTool");
                      addQueryParam("page", "addTool");
                      setFlag(true);
                    }}
                  >
                    Add New Tool
                  </Button>
                </div>
              </div>
              <div className="tool-table">
                <div className="user-table table table-responsive">
                  <div className="table-responsive">
                    <Table className="main-table" striped>
                      <thead className="user-table-head">
                        <tr>
                          <th>Tool Name </th>
                          <th>Tool Description</th>
                          <th>Power Users </th>
                          <th>Tool Type</th>
                          <th>Action</th>
                        </tr>
                        <tr style={{ height: "3.5rem" }}>
                          <th>
                            <div className="app-primary-input-field mb-2">
                              <input
                                className="me-3 w-100"
                                type="search"
                                placeholder=" Search by name..."
                                value={nameQuery}
                                onChange={(e) => {
                                  setNameQuery(e.target.value);
                                  nameSearch(e.target.value.toLowerCase());
                                }}
                              />
                            </div>
                          </th>
                          <th>
                            <div className="app-primary-input-field mb-2">
                              <input
                                className="me-3 w-100"
                                type="search"
                                placeholder=" Search by description..."
                                value={descQuery}
                                onChange={(e) => {
                                  setDescQuery(e.target.value);
                                  descSearch(e.target.value.toLowerCase());
                                }}
                              />
                            </div>
                          </th>
                          <th>
                            <div className="app-primary-input-field mb-2">
                              <input
                                disabled
                                className="me-3 w-100"
                                type="search"
                                placeholder="Disabled"
                              />
                            </div>
                          </th>
                          <th>
                            <div
                              className="app-primary-dropdown mb-2"
                              style={{ position: "absolute", width: "26%" }}
                            >
                              <Typeahead
                                multiple={false}
                                selected={typeQuery}
                                labelKey="name"
                                emptyLabel=""
                                placeholder="Search by tool type..."
                                id="typeAhead"
                                clearButton
                                searchable
                                onChange={typeSearch}
                                options={layerList}
                              />
                            </div>
                          </th>
                          <th>
                            <div className="app-primary-input-field mb-2">
                              <input
                                disabled
                                className="me-3 w-100"
                                type="search"
                                placeholder="Disabled"
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {toolData && toolData.length > 0 ? (
                          <>
                            {toolData?.map((item) => (
                              <tr key={item.id} className="table-striped-bg">
                                <td>{item.attributes["tool-name"]}</td>
                                <td>{item.attributes["tool-description"]}</td>
                                <td className="px-0">
                                  <>
                                    <span style={{ cursor: "pointer" }}>
                                      {experts[item.id] ? (
                                        <>
                                          {experts[item.id]?.length <= 3 ? (
                                            <>
                                              {experts[item.id]?.map(
                                                (expert, length) => {
                                                  return (
                                                    <span
                                                      className="px-1"
                                                      onClick={() => {
                                                        navigate(
                                                          `/employee_relation?id=${expert?.attributes["employee-id"]}`
                                                        );
                                                      }}
                                                    >
                                                      {expert?.attributes
                                                        ?.employee?.data
                                                        ?.attributes[
                                                        "profile-picture"
                                                      ] ? (
                                                        <img
                                                          className="my-auto"
                                                          src={`${getBaseUrl()}${
                                                            expert?.attributes
                                                              ?.employee?.data
                                                              ?.attributes[
                                                              "profile-picture"
                                                            ]
                                                          }`}
                                                          style={{
                                                            height: "2rem",
                                                            width: "2rem",
                                                            border:
                                                              "1.5px solid black",
                                                            borderRadius:
                                                              "7rem",
                                                            objectFit:
                                                              "contain",
                                                          }}
                                                          alt="User Img"
                                                        />
                                                      ) : (
                                                        <Avatar
                                                          name={
                                                            expert?.attributes
                                                              ?.employee &&
                                                            expert?.attributes
                                                              ?.employee?.data
                                                              ?.attributes[
                                                              "preferred-name"
                                                            ]
                                                          }
                                                          size="32"
                                                          round={true}
                                                        />
                                                      )}
                                                    </span>
                                                  );
                                                }
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {experts[item.id]?.map(
                                                // eslint-disable-next-line
                                                (expert, index) => {
                                                  if (index <= 2) {
                                                    return (
                                                      <span
                                                        className="px-1"
                                                        onClick={() => {
                                                          navigate(
                                                            `/employee_relation?id=${expert?.attributes["employee-id"]}`
                                                          );
                                                        }}
                                                      >
                                                        {expert?.attributes
                                                          ?.employee?.data
                                                          ?.attributes[
                                                          "profile-picture"
                                                        ] ? (
                                                          <CustomTooltip
                                                            name={
                                                              expert?.attributes
                                                                ?.employee &&
                                                              expert?.attributes
                                                                ?.employee?.data
                                                                ?.attributes[
                                                                "preferred-name"
                                                              ]
                                                            }
                                                            orgCode={
                                                              expert?.attributes
                                                                ?.employee &&
                                                              expert?.attributes
                                                                ?.employee?.data
                                                                ?.attributes[
                                                                "employee-org-master"
                                                              ].data.attributes[
                                                                "org-title"
                                                              ]
                                                            }
                                                          >
                                                            <img
                                                              className="my-auto"
                                                              src={`${getBaseUrl()}${
                                                                expert
                                                                  ?.attributes
                                                                  ?.employee
                                                                  ?.data
                                                                  ?.attributes[
                                                                  "profile-picture"
                                                                ]
                                                              }`}
                                                              style={{
                                                                height: "2rem",
                                                                width: "2rem",
                                                                border:
                                                                  "1.5px solid black",
                                                                borderRadius:
                                                                  "7rem",
                                                                objectFit:
                                                                  "contain",
                                                              }}
                                                              alt="User Img"
                                                            />
                                                          </CustomTooltip>
                                                        ) : (
                                                          <CustomTooltip
                                                            name={
                                                              expert?.attributes
                                                                ?.employee &&
                                                              expert?.attributes
                                                                ?.employee?.data
                                                                ?.attributes[
                                                                "preferred-name"
                                                              ]
                                                            }
                                                            orgCode={
                                                              expert?.attributes
                                                                ?.employee &&
                                                              expert?.attributes
                                                                ?.employee?.data
                                                                ?.attributes[
                                                                "employee-org-master"
                                                              ].data.attributes[
                                                                "org-title"
                                                              ]
                                                            }
                                                          >
                                                            <Avatar
                                                              name={
                                                                expert
                                                                  ?.attributes
                                                                  ?.employee &&
                                                                expert
                                                                  ?.attributes
                                                                  ?.employee
                                                                  ?.data
                                                                  ?.attributes[
                                                                  "preferred-name"
                                                                ]
                                                              }
                                                              size="32"
                                                              round={true}
                                                            />
                                                          </CustomTooltip>
                                                        )}
                                                      </span>
                                                    );
                                                  }
                                                }
                                              )}
                                              <button
                                                className="more-user-btn"
                                                onClick={() => {
                                                  setUsersData(
                                                    experts[item.id]
                                                  );
                                                  setShowUserModal(true);
                                                }}
                                              >
                                                + {experts[item.id]?.length - 3}{" "}
                                                more
                                              </button>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </span>
                                  </>
                                </td>

                                <td className="px-0">
                                  <span>
                                    {item["attributes"].nodes["data"].length ===
                                    0 ? (
                                      <span className="me-2">
                                        <Link
                                          style={{ textDecoration: "none" }}
                                          to={`/landscape/tools?layerId=4&toolId=${item.id}`}
                                          state={{
                                            prevPath: window.location.href,
                                          }}
                                        >
                                          <ImageSvgs name="not_in_use" />{" "}
                                        </Link>
                                      </span>
                                    ) : (
                                      [
                                        ...new Set(
                                          item["attributes"]["nodes"][
                                            "data"
                                          ].map(
                                            (item) =>
                                              item["attributes"]["layer-id"]
                                          )
                                        ),
                                      ].map((element) => {
                                        if (element === 1) {
                                          return (
                                            <span className="me-2">
                                              <Link
                                                style={{
                                                  textDecoration: "none",
                                                }}
                                                to={`/landscape/tools?layerId=1&toolId=${item.id}`}
                                                state={{
                                                  prevPath:
                                                    window.location.href,
                                                }}
                                              >
                                                <ImageSvgs name="ui_layer" />
                                              </Link>
                                            </span>
                                          );
                                        } else if (element === 2) {
                                          return (
                                            <span className="me-2">
                                              <Link
                                                style={{
                                                  textDecoration: "none",
                                                }}
                                                to={`/landscape/tools?layerId=2&toolId=${item.id}`}
                                                state={{
                                                  prevPath:
                                                    window.location.href,
                                                }}
                                              >
                                                <ImageSvgs name="integration_layer" />
                                              </Link>
                                            </span>
                                          );
                                        } else if (element === 3) {
                                          return (
                                            <span className="me-2">
                                              <Link
                                                style={{
                                                  textDecoration: "none",
                                                }}
                                                to={`/landscape/tools?layerId=3&toolId=${item.id}`}
                                                state={{
                                                  prevPath:
                                                    window.location.href,
                                                }}
                                              >
                                                <ImageSvgs name="source_layer" />
                                              </Link>
                                            </span>
                                          );
                                        } else
                                          return (
                                            <span className="me-2">
                                              <Link
                                                style={{
                                                  textDecoration: "none",
                                                }}
                                                to={`/landscape/tools?layerId=4&toolId=${item.id}`}
                                                state={{
                                                  prevPath:
                                                    window.location.href,
                                                }}
                                              >
                                                <ImageSvgs name="not_in_use" />{" "}
                                              </Link>
                                            </span>
                                          );
                                      })
                                    )}
                                  </span>
                                </td>
                                <td className="d-flex justify-content-right px-0">
                                  <Nav.Link
                                    onClick={() => {
                                      setEditToolData(item);
                                      setToolLevel("editTool");
                                      addQueryParam("page", "editTool");
                                      setEmployeeData(employeeData);
                                      setIncluded(included);
                                    }}
                                  >
                                    <span>
                                      {" "}
                                      <ImageSvgs name="edit" />
                                    </span>
                                    &nbsp;
                                  </Nav.Link>

                                  <button
                                    className="border-0 bg-transparent mb-auto px-3"
                                    onClick={() => {
                                      setShowModal(true);
                                      setDeleteToolData(item);
                                    }}
                                  >
                                    <ImageSvgs name="delete" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <tr className="w-100">
                            <td
                              className="w-100"
                              style={{
                                textAlign: "center",
                                backgroundColor: "#e9ecef ",
                              }}
                            >
                              <p className="fw-bold mt-1 mb-0">
                                No matching records found.
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </>
          ) : toolLevel === "addTool" ? (
            <AddNewTool
              title="Add New Tool"
              includedData={included}
              communityData={communityData && communityData}
              employeeData={employeeData && employeeData}
              trainingData={trainingData && trainingData}
              iniToolData={iniToolData && iniToolData}
              navigateToPage={navigateToPage}
              setFlag={setFlag}
            />
          ) : toolLevel === "editTool" ? (
            <AddNewTool
              title="Edit Tool"
              includedData={included}
              data={editToolData ? editToolData : null}
              employeeData={employeeData && employeeData}
              addTool={toolLevel && toolLevel}
              communityData={communityData && communityData}
              trainingData={trainingData && trainingData}
              iniToolData={iniToolData && iniToolData}
              navigateToPage={navigateToPage}
              setFlag={setFlag}
              setUsersData={setUsersData}
            />
          ) : null}
        </>
        {showModal === true ? (
          <DeleteToolModal
            title={"Delete Tool"}
            name={deleteToolData["attributes"]["tool-name"]}
            deletetool={deleteToolData}
            modalmessage={
              deleteToolData["relationships"]["apps"]["data"].length === 0 ? (
                <span className="d-flex flex-column pt-3">
                  <span>Are you sure you want to delete this tool?</span>
                </span>
              ) : (
                <span className="d-flex flex-column">
                  {deleteToolData["attributes"]["tool-name"]} is connected to{" "}
                  {deleteToolData &&
                    deleteToolData.relationships.apps.data.length}{" "}
                  application:
                  <Container>
                    <Row className="p-2">
                      {deleteToolData &&
                        deleteToolData.relationships.apps.data?.map(
                          (listItem) => {
                            return (
                              <Col className="border-0 ">
                                <span>
                                  <strong>
                                    {" "}
                                    {
                                      applicationName[listItem.id][0]
                                        .attributes["app-title"]
                                    }
                                  </strong>
                                </span>
                              </Col>
                            );
                          }
                        )}
                    </Row>
                  </Container>
                  <span>
                    In order to delete{" "}
                    <strong>
                      {deleteToolData["attributes"]["tool-name"]}{" "}
                    </strong>{" "}
                    from the list all connections mentioned above must be
                    removed first.
                  </span>
                </span>
              )
            }
            show={showModal}
            onHide={() => setShowModal(false)}
            setFlag={setFlag}
          />
        ) : (
          ""
        )}
        {showUserModal === true ? (
          <UserModal
            title={"Power Users"}
            heading={"List of Power Users :"}
            listData={usersData}
            show={showUserModal}
            onHide={() => setShowUserModal(false)}
          />
        ) : (
          " "
        )}
      </div>
    </>
  );
};

export default ToolsTable;
