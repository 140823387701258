import { useState, useEffect } from "react";
import "../assets/scss/common.scss";
import ImageSvgs from "../components/ImageSvgs";
import { getData } from "../services/apiService";
import { getHostUrl } from "../config/environment";
import { useNavigate } from "react-router-dom";
import ProcessTable from "../components/ProcessAdminComponent/ProcessTable";
import "../components/ProcessAdminComponent/ProcessAdminPage.scss";
import AddAndEditProcess from "../components/ProcessAdminComponent/AddAndEditProcess";
import AddProcessSteps from "../components/ProcessAdminComponent/AddProcessSteps";
import TopicTable from "../components/TopicsAdminComponent/TopicTable";
import AddTopic from "../components/TopicsAdminComponent/AddTopic";
import ViewTopic from "../components/TopicsAdminComponent/ViewTopic";
import PreviewProcess from "../components/ProcessAdminComponent/PreviewProcess";
import { ButtonGroup, Button } from "react-bootstrap";
import Loader from "../components/Loader/Loader";
import GroupModal from "../components/ProcessAdminComponent/ProcessGroupModal";

export default function ManageProcess() {
  // eslint-disable-next-line
  const [res, setRes] = useState(false);
  // eslint-disable-next-line
  const [flag, setFlag] = useState(false);
  // eslint-disable-next-line
  const [employeeData, setEmployeeData] = useState();
  // eslint-disable-next-line
  const [trainingData, setTrainingData] = useState();
  // eslint-disable-next-line
  const [communityData, setCommunityData] = useState();
  // eslint-disable-next-line
  const [applicationData, setApplicationData] = useState();
  // eslint-disable-next-line
  const [organizationData, setOrganisationData] = useState();
  // eslint-disable-next-line
  const [groupData, setGroupsData] = useState();
  const [processIncludedData, setProcessIncludedData] = useState();
  // eslint-disable-next-line
  const [iniTopicData, setIniTopicData] = useState();
  // eslint-disable-next-line
  const [tableLoad, setTableLoad] = useState(false);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [topicIncludedData, setTopicInculdedData] = useState();
  const [processViewData, setProcessViewData] = useState();
  const [viewTopicData, setViewTopicData] = useState();
  const [addProcessData, setAddProcessData] = useState();
  const [editUpdateDataPage, setEditUpdateDataPage] = useState();
  const [topicEditData, setEditTopicData] = useState();
  const [processEditData, setProcessEditData] = useState();
  const [processData, setProcessData] = useState(null);
  const [topicData, setTopicData] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const url = new URL(window.location.href);
  const navigate = useNavigate();
  const [pageLevel, setPageLevel] = useState(url.searchParams.get("page"));
  const [key, setKey] = useState("1");
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    setEditId(editId);
  }, [editId]);

  useEffect(() => {
    (async () => {
      if (pageLevel === "topic" || pageLevel === "process") {
        const param = `${getHostUrl()}topics?include=orgs,owners&use_pagination=false`;
        const res = await getData(param);
        await setTopicData(res.data.data);
        await setTopicInculdedData(res.data.included);
      }
      setFlag(false);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      const param1 = `${getHostUrl()}process?template=process_template_table_view&use_pagination=false`;
      const res1 = await getData(param1);
      await setProcessData(res1.data.data);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        if (editId) {
          const param = `${getHostUrl()}process/${editId}?include=owners,trainings,child_processes,parent_processes,communities,apps,orgs&use_pagination=false`;
          const response = await getData(param);
          setProcessEditData(response.data.data);
          setProcessIncludedData(response.data.included);

          await sessionStorage.setItem(
            "processEditData",
            JSON.stringify(response.data.data && response.data.data)
          );
          await sessionStorage.setItem(
            "processIncluded",
            JSON.stringify(response.data.included && response.data.included)
          );
          sessionStorage.setItem("updateId", editId);
        } else setProcessEditData(sessionStorage.getItem("processEditData"));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        addQueryParam("page", "editProcess");
        setPageLevel("editProcess");
        setLoading(false);
      }
    };

    if (editId) {
      loadData();
    }
  }, [editId]);

  useEffect(() => {
    if (pageLevel !== "editProcess") {
      addQueryParam("page", pageLevel);
    } else {
      addQueryParam("page", pageLevel);
    }
  }, [pageLevel]);

  useEffect(() => {
    const fetchProcessData = async () => {
      const isCommunityDataStored = sessionStorage.getItem("communityAll");
      const isTrainingDataStored = sessionStorage.getItem("trainingAll");
      const isApplicationDataStored = sessionStorage.getItem("applicationAll");
      const isOrgDataStored = sessionStorage.getItem("orgAll");
      const isAllProcessGroupsStored =
        sessionStorage.getItem("allProcessGroups");
      const isProcessDataStored = sessionStorage.getItem("processData");

      if (
        isCommunityDataStored &&
        isTrainingDataStored &&
        isApplicationDataStored &&
        isOrgDataStored &&
        isAllProcessGroupsStored &&
        isProcessDataStored
      ) {
        return;
      }

      try {
        const adRes = await getData(
          `${getHostUrl()}user/check/${
            JSON.parse(sessionStorage.getItem("userData")).id
          }`
        );
        await setIsAdmin(adRes.data);

        // if (adRes.data === true) {
        const community = `${getHostUrl()}community?use_pagination=false`;
        const training = `${getHostUrl()}training?use_pagination=false`;
        const application = `${getHostUrl()}application?use_pagination=false`;
        const orgData = `${getHostUrl()}organization/search?search=SE FIN GS&use_pagination=false`;

        const groupsLink = `${getHostUrl()}process/groups`;

        const [res3, res4, res5, res6, res7] = await Promise.all([
          getData(community),
          getData(training),
          getData(application),
          getData(orgData),
          getData(groupsLink),
        ]);
        sessionStorage.setItem("communityAll", JSON.stringify(res3.data));
        sessionStorage.setItem("trainingAll", JSON.stringify(res4.data));
        sessionStorage.setItem("applicationAll", JSON.stringify(res5.data));
        sessionStorage.setItem("orgAll", JSON.stringify(res6.data));
        sessionStorage.setItem(
          "allProcessGroups",
          JSON.stringify(res7.data.data)
        );
      } catch (err) {
        if (err.response?.status === 401) {
          console.error("Unauthorized access:", err);
        } else {
          console.error("Error fetching process data:", err);
        }
      }
    };

    fetchProcessData();
  }, []);

  const addQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, "", url.toString());
  };

  return (
    <div className="main-page-margin">
      {isAdmin !== null ? (
        <>
          {isAdmin ? (
            <div className="main-manage">
              <>
                <div className="process-header">
                  <button
                    className="fw-bold border-0 bg-transparent"
                    onClick={() => {
                      sessionStorage.removeItem("processPayload");
                      sessionStorage.removeItem("processConfig");
                      if (
                        sessionStorage.getItem("processFlag") &&
                        sessionStorage.getItem("processFlag") === "true"
                      ) {
                        sessionStorage.removeItem("processFlag");
                        sessionStorage.removeItem("processEditData");
                        sessionStorage.removeItem("processIncluded");
                        sessionStorage.removeItem("applicationAll");
                        sessionStorage.removeItem("trainingAll");
                        sessionStorage.removeItem("communityAll");
                        sessionStorage.removeItem("orgAll");
                        sessionStorage.removeItem("allProcessGroups");
                        navigate(
                          "/landscape/process?process_id=0&view=cardView"
                        );
                      }
                      if (pageLevel === "process") {
                        navigate("/manage_users");
                      } else if (pageLevel === "addTopic") {
                        setPageLevel("topic");
                      } else if (pageLevel === "editTopic") {
                        setPageLevel("topic");
                      } else if (pageLevel === `topic_id${viewTopicData?.id}`) {
                        setPageLevel("topic");
                      } else if (pageLevel === "editPreview") {
                        setPageLevel("editProcess");
                        setFlag(false);
                      } else if (pageLevel === "addPreview") {
                        setPageLevel("addProcess");
                        setFlag(false);
                      } else if (pageLevel === "addProcess") {
                        setPageLevel("process");
                      } else if (pageLevel === "topic") {
                        navigate("/manage_users");
                      } else if (pageLevel === "editProcess") {
                        sessionStorage.removeItem("processEditData");
                        sessionStorage.removeItem("processIncluded");
                        sessionStorage.removeItem("updateId");
                        setEditId(null);
                        setPageLevel("process");
                      } else {
                        setPageLevel("process");
                      }
                    }}
                  >
                    <ImageSvgs name="goBack" /> Back
                  </button>
                  {pageLevel === "topic" || pageLevel === "process" ? (
                    <div className=" d-flex justify-content-between ">
                      <div>
                        <ButtonGroup className="me-2 process-btn">
                          <Button
                            className={`main-title-option ${
                              key === "1" && pageLevel === "process"
                                ? "active-option"
                                : "not-active main-title-option "
                            } `}
                            onClick={() => {
                              setKey("1");
                              setPageLevel("process");
                              addQueryParam("page", "process");
                            }}
                          >
                            Processes ({processData && processData?.length})
                          </Button>
                          <Button
                            className={`main-title-option ${
                              key === "2"
                                ? "active-option"
                                : "main-title-option"
                            } `}
                            onClick={() => {
                              setKey("2");
                              setPageLevel("topic");
                              addQueryParam("page", "topic");
                            }}
                          >
                            Topics ({topicData && topicData.length})
                          </Button>
                        </ButtonGroup>
                      </div>
                      <div className="sub-bar">
                        <div className="search-div">
                          {pageLevel === "process" ? (
                            <div>
                              <Button
                                className="app-secondary-btn mx-2"
                                onClick={() => {
                                  setShowGroupModal(true);
                                }}
                              >
                                Manage Groups
                              </Button>

                              <Button
                                className="app-secondary-btn"
                                onClick={() => {
                                  setPageLevel("addProcess");
                                  addQueryParam("page", "addProcess");
                                  sessionStorage.removeItem("formData");
                                  sessionStorage.removeItem("processEditData");
                                }}
                              >
                                Add New Process
                              </Button>
                            </div>
                          ) : (
                            <Button
                              className="app-secondary-btn"
                              onClick={() => {
                                setPageLevel("addTopic");
                                addQueryParam("page", "addTopic");
                              }}
                            >
                              Add New Topic
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                {pageLevel === "process" ? (
                  <div>
                    <div className="process-table">
                      <div className="user-table table table-responsive">
                        <ProcessTable
                          setPageLevel={setPageLevel}
                          addQueryParam={addQueryParam}
                          setFlag={setFlag}
                          // setEditId={setEditId}
                          setProcessViewData={setProcessViewData}
                          organizationData={organizationData}
                          setTableLoad={setTableLoad}
                          setEditId={setEditId}
                        />
                      </div>
                    </div>
                  </div>
                ) : pageLevel === "topic" ? (
                  <div className="topic-table">
                    <div className="user-table table table-responsive">
                      <TopicTable
                        setPageLevel={setPageLevel}
                        addQueryParam={addQueryParam}
                        topicData={topicData && topicData}
                        setFlag={setFlag}
                        setEditTopicData={setEditTopicData}
                        topicIncludedData={topicIncludedData}
                        organizationData={organizationData && organizationData}
                        employeeData={employeeData && employeeData}
                        setViewTopicData={setViewTopicData}
                        editUpdateDataPage={
                          editUpdateDataPage && editUpdateDataPage
                        }
                      />
                    </div>
                  </div>
                ) : pageLevel === "addProcess" ? (
                  <AddAndEditProcess
                    title={"Add New Process"}
                    setPageLevel={setPageLevel}
                    addQueryParam={addQueryParam}
                    setFlag={setFlag}
                    processData={processData}
                    pageLevel={pageLevel}
                    organizationData={organizationData && organizationData}
                    processIncludedData={processIncludedData}
                    trainingData={trainingData}
                    applicationData={applicationData}
                    communityData={communityData}
                    setProcessViewData={setProcessViewData}
                    setEditUpdateDataPage={setEditUpdateDataPage}
                    setAddProcessData={setAddProcessData}
                    addProcessData={addProcessData && addProcessData}
                    groupsList={groupData && groupData}
                    setTableLoad={setTableLoad}
                  />
                ) : pageLevel === "addSteps" ? (
                  <AddProcessSteps
                    pageLevel={pageLevel}
                    setPageLevel={setPageLevel}
                    setFlag={setFlag}
                  />
                ) : pageLevel === "editProcess" ? (
                  <>
                    {loading === false ||
                    (sessionStorage.getItem("processFlag") &&
                      sessionStorage.getItem("processFlag") === "true") ? (
                      <AddAndEditProcess
                        title={"Edit Process"}
                        setPageLevel={setPageLevel}
                        addQueryParam={addQueryParam}
                        setFlag={setFlag}
                        processData={processData}
                        processEditData={processEditData && processEditData}
                        pageLevel={pageLevel}
                        organizationData={organizationData}
                        processIncludedData={processIncludedData}
                        trainingData={trainingData}
                        applicationData={applicationData}
                        communityData={communityData}
                        editId={editId}
                        setProcessViewData={setProcessViewData}
                        groupsList={groupData && groupData}
                        editData={processEditData}
                      />
                    ) : (
                      <div style={{ maxWidth: "100%", height: "100vh" }}>
                        <Loader />
                      </div>
                    )}
                  </>
                ) : pageLevel === "editSteps" ? (
                  <AddProcessSteps
                    pageLevel={pageLevel}
                    setPageLevel={setPageLevel}
                    setFlag={setFlag}
                  />
                ) : pageLevel === "addTopic" ? (
                  <AddTopic
                    title={"Add New Topic"}
                    setPageLevel={setPageLevel}
                    addQueryParam={addQueryParam}
                    setFlag={setFlag}
                    topicIncludedData={topicIncludedData}
                    organizationData={organizationData && organizationData}
                    setEditTopicData={setEditTopicData}
                    iniTopicData={iniTopicData && iniTopicData}
                  />
                ) : pageLevel === `topic_id${viewTopicData?.id}` ? (
                  <ViewTopic
                    title={"View Topic"}
                    viewTopicData={viewTopicData && viewTopicData}
                    topicIncludedData={topicIncludedData && topicIncludedData}
                  />
                ) : pageLevel === "editTopic" ? (
                  <AddTopic
                    title={"Edit Topic"}
                    addQueryParam={addQueryParam}
                    setPageLevel={setPageLevel}
                    setFlag={setFlag}
                    topicEditData={topicEditData}
                    pageLevel={pageLevel}
                    topicIncludedData={topicIncludedData}
                    organizationData={organizationData && organizationData}
                    iniTopicData={iniTopicData && iniTopicData}
                  />
                ) : pageLevel === "editPreview" ? (
                  <PreviewProcess
                    title={"Preview"}
                    processViewData={processViewData}
                    processEditData={processEditData}
                    processIncludedData={processIncludedData}
                    applicationData={applicationData && applicationData}
                    communityData={communityData && communityData}
                    organizationData={organizationData && organizationData}
                    trainingData={trainingData && trainingData}
                    processData={processData && processData}
                    employeeData={employeeData}
                  />
                ) : pageLevel === "addPreview" ? (
                  <PreviewProcess
                    title={"Preview"}
                    processViewData={processViewData}
                    processEditData={processEditData}
                    processIncludedData={processIncludedData}
                    applicationData={applicationData && applicationData}
                    communityData={communityData && communityData}
                    organizationData={organizationData && organizationData}
                    trainingData={trainingData && trainingData}
                    processData={processData && processData}
                    employeeData={employeeData}
                  />
                ) : pageLevel === "viewProcessDetails" ? (
                  <PreviewProcess
                    title={"View Details"}
                    processViewData={processViewData && processViewData}
                    processIncludedData={processIncludedData}
                  />
                ) : (
                  <div className="non-admin">
                    <ImageSvgs name="non_admin" />
                    <h1 className="text-danger">Unauthorized user!</h1>
                    <h4>You do not have admin access rights.</h4>
                    <button
                      id="common-button"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Go to Home
                    </button>
                  </div>
                )}
              </>
            </div>
          ) : (
            <div
              className="non-admin d-flex justify-content-center"
              style={{ height: "100vh" }}
            >
              <div className="my-auto">
                <span className="mx-auto d-flex justify-content-center">
                  <ImageSvgs name="non_admin" />
                </span>
                <h1 className="text-danger d-flex justify-content-center">
                  Unauthorized user!
                </h1>
                <h4 className="mx-auto d-flex justify-content-center">
                  You do not have admin access rights.
                </h4>
                <button
                  className="d-flex mx-auto"
                  id="common-button"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Go to Home
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div style={{ maxWidth: "100%", height: "100vh" }}>
          <Loader />
        </div>
      )}

      {showGroupModal ? (
        <GroupModal
          show={showGroupModal}
          onHide={() => setShowGroupModal(false)}
        />
      ) : null}
    </div>
  );
}
