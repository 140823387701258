import React from "react";
import ProcessUserPage from "./ProcessUserPage";
import Offcanvas from "react-bootstrap/Offcanvas";

export default function ProcessDetailsPage({
  hide,
  show,
  data,
  activeData,
  deleted,
  setIsDeleted,
  allLoading,
  activeBtn,
  included,
}) {
  return (
    <div>
      <Offcanvas
        show={show}
        onHide={hide}
        placement="end"
        sm={12}
        lg={12}
        style={{ width: "80%" }}
      >
        <Offcanvas.Body>
          <ProcessUserPage
            set
            data={data}
            hide={hide}
            activeData={activeData}
            deleted={deleted}
            setIsDeleted={setIsDeleted}
            allLoading={allLoading}
            view={activeBtn}
            included={included}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
