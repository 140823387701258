import { useState, useEffect, useRef } from "react";
import "../../assets/scss/common.scss";
import { chunk } from "lodash";
import { Carousel, Stack } from "react-bootstrap";
import { nextArrow } from "../../utils/iconUtils";
import { prevArrow } from "../../utils/iconUtils";
import ExpertsComponent from "../CommonComponents/ExpertsComponent";
import ConnectionsComponent from "../CommonComponents/ConnectionsComponent";
import MyVerticallyCenteredModal from "../CommonComponents/ReadMore";
import "./ProcessAdminPage.scss";

export default function PreviewProcess(props) {
  const formDataString = sessionStorage.getItem("formData");
  const formDataSet = JSON.parse(formDataString || "{}");
  const pageData = formDataSet.data;
  const communityData = props?.communityData?.data;
  const applicationData = props?.applicationData?.data;
  const trainingData = props?.trainingData?.data;
  const processData = props?.processData;
  const organizationData = props?.organizationData?.data;
  const [processConnections, setProcessConnections] = useState();
  const [processOwner, setProcessOwner] = useState();
  const periodData =
    pageData?.attributes["periodicity_period"] != null &&
    pageData?.attributes["periodicity_period"][0];
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const widthRef = useRef(null);

  useEffect(() => {
    setData(pageData);
    // eslint-disable-next-line
  }, []);

  const processId = formDataSet?.data.attributes["parent_process_id"];

  const processName = processData?.filter(
    (data) =>
      data.type === "process" && data?.id?.toString() === processId?.toString()
  );

  useEffect(() => {
    (async () => {
      const e = widthRef.current;
      if (e?.offsetHeight < e?.scrollHeight) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    })();

    // eslint-disable-next-line
  }, []);

  const setData = async (data) => {
    setProcessOwner(pageData?.relationships.owners);
    if (data?.attributes?.desc?.length > 300) {
      setIsOverflowing(true);
    }
    let appData =
      data?.relationships.apps?.length > 0
        ? data?.relationships.apps?.map((item) => {
            let apps =
              applicationData &&
              applicationData?.filter(
                (app) =>
                  app?.type === "application" &&
                  app?.id?.toString() ===
                    item?.attributes["application_id"]?.toString()
              )[0];
            return apps;
          })
        : [];

    let comData =
      data?.relationships.communities.length > 0
        ? data?.relationships.communities.map((item) => {
            let community =
              communityData &&
              communityData?.filter(
                (com) =>
                  com?.type === "community" &&
                  item?.attributes["community_id"]?.toString() ===
                    com?.id?.toString()
              )[0];
            return community;
          })
        : [];

    let trainingValue =
      data?.relationships.trainings?.length > 0
        ? data?.relationships.trainings.map((item) => {
            let trainings =
              trainingData &&
              trainingData?.filter(
                (value) =>
                  value?.type === "training" &&
                  value?.id?.toString() ===
                    item?.attributes["training_id"]?.toString()
              )[0];
            return trainings;
          })
        : [];

    let orgData =
      data?.relationships.orgs.length > 0
        ? data?.relationships.orgs.map((item) => {
            let orgs =
              organizationData &&
              organizationData?.filter(
                (org) =>
                  org?.type === "organization" &&
                  org?.id?.toString() === item?.attributes["org_id"]?.toString()
              )[0];
            return orgs;
          })
        : [];

    setProcessConnections({
      appData: chunk(appData, 5),
      comData: chunk(comData, 5),
      orgData: chunk(orgData, 5),
      trainingValue: chunk(trainingValue, 5),
    });
  };

  return (
    <div className="preview-page">
      <div className="d-flex justify-content-between pt-2 pb-3">
        <p className="main-title">{props.title}</p>
      </div>
      <div>
        <div className="pb-2">
          {" "}
          <span className="custom-font-size">
            <strong>Process Name : {pageData?.attributes?.title}</strong>
          </span>
        </div>

        <div className="pb-2 ">
          <p
            className={
              isOverflowing ? "limited-space mx-0" : "not-read-more mx-0"
            }
            ref={widthRef}
          >
            <b>Description: </b>
            {pageData?.attributes?.desc}
          </p>
          <p
            className="ms-3 mx-0 mb-0 read-more-btn"
            style={{ cursor: "pointer", color: "#8a00e5" }}
            onClick={() => {
              setShowInfoModal(true);
            }}
          >
            {isOverflowing ? "Read more" : null}
          </p>
        </div>

        <div className="d-flex flex-row ">
          <span className="d-flex flex-column pe-4 pb-2 me-5">
            <span className="pb-2">
              <strong>Process Date & Time: </strong>
              <span>
                {" "}
                {pageData?.attributes["process_day"] ? (
                  pageData?.attributes["process_day"]
                ) : (
                  <span className="text-muted"> N/A </span>
                )}{" "}
              </span>{" "}
              ,
              <span>
                {" "}
                {pageData?.attributes["process_time"] ? (
                  pageData?.attributes["process_time"]
                ) : (
                  <span className="text-muted"> N/A </span>
                )}
              </span>
            </span>

            <span className="pb-2">
              <strong>Wikipages Link:</strong>
              {pageData?.attributes["wiki_link_url"] ? (
                <a
                  href={pageData?.attributes["wiki_link_url"]}
                  className="custom-link px-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  {pageData?.attributes["wiki_link_url"]}
                  {""}
                </a>
              ) : (
                <span className="text-muted px-2"> N/A </span>
              )}
            </span>
          </span>

          <span className="d-flex flex-column pe-4 pb-2 me-5">
            <span className="pb-2">
              <strong>Parent Process: </strong>{" "}
              {processName ? (
                processName[0]?.attributes?.title
              ) : (
                <span className="text-muted"> N/A </span>
              )}
            </span>

            <span className="d-flex flex-row pb-2">
              <strong>Handbook Link:</strong>
              {pageData?.attributes["wiki_link_url"] ? (
                <a
                  href={pageData?.attributes["handbook_link_url"]}
                  className="custom-link px-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  {pageData?.attributes["handbook_link_url"]}{" "}
                </a>
              ) : (
                <span className="text-muted px-2"> N/A </span>
              )}
            </span>
          </span>

          <span className="d-flex flex-column pe-4 pb-2 me-5">
            <span className="d-flex flex-row pb-2 ">
              {" "}
              <strong>Process Periodicity:</strong>
              <span className="px-2">
                {" "}
                {periodData?.name ? (
                  periodData?.name
                ) : (
                  <span className="text-muted"> N/A </span>
                )}
              </span>
            </span>
          </span>
        </div>
      </div>

      <div>
        <div>
          <span>
            <strong>
              Owner & Deputy of the {pageData?.attributes.title} are : -
            </strong>
          </span>
          <div className="experts-section mt-3">
            <div className="d-flex flex-row justify-content-between">
              {processOwner?.length > 0 ? (
                <Carousel
                  nextIcon={nextArrow}
                  prevIcon={prevArrow}
                  interval={null}
                  wrap={false}
                  indicators={false}
                >
                  {chunk(processOwner, 5)?.map((record, i) => (
                    <Carousel.Item key={i}>
                      <Stack
                        direction="horizontal"
                        className="h-100 align-items-center"
                        gap={2}
                      >
                        {record.map((elem, j) => {
                          if (j < record.length - 1) {
                            return (
                              <>
                                <ExpertsComponent
                                  key={j}
                                  data={elem}
                                  owner={elem?.attributes["is-owners"]}
                                />
                                <div className="expert-divider"></div>
                              </>
                            );
                          } else {
                            return (
                              <ExpertsComponent
                                key={j}
                                data={elem}
                                owner={elem?.attributes["is-owners"]}
                              />
                            );
                          }
                        })}
                      </Stack>
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : null}
            </div>
          </div>
          <div>
            <div className="section">
              <div className="innerSec">
                <div className="d-flex flex-column mt-3">
                  <div className="p-3 page-connections-section">
                    <div className="mt-4 d-flex flex-column ">
                      {processConnections?.comData.length > 0 ? (
                        <h6 className="fw-bold ms-4 d-flex">Communities</h6>
                      ) : (
                        <h6 className="fw-bold ms-4 d-flex">
                          Communities:
                          <p className="mb-0 ms-2 not-applicable text-muted">
                            N/A
                          </p>
                        </h6>
                      )}
                      <div className="d-flex flex-row justify-content-center">
                        {processConnections?.comData.length > 0 && (
                          <Carousel
                            nextIcon={nextArrow}
                            prevIcon={prevArrow}
                            interval={null}
                            wrap={false}
                            indicators={false}
                          >
                            {processConnections?.comData.map((record, i) => (
                              <Carousel.Item key={i}>
                                <Stack
                                  direction="horizontal"
                                  className="h-100  align-items-center"
                                  gap={2}
                                >
                                  {record.map((elem, j) => {
                                    if (j < record.length - 1) {
                                      return (
                                        <>
                                          <ConnectionsComponent
                                            data={elem}
                                            key={j}
                                          />
                                          <div className="divider"></div>
                                        </>
                                      );
                                    } else {
                                      return (
                                        <ConnectionsComponent
                                          data={elem}
                                          key={j}
                                        />
                                      );
                                    }
                                  })}
                                </Stack>
                              </Carousel.Item>
                            ))}
                          </Carousel>
                        )}
                      </div>
                    </div>

                    <div className="mt-4 d-flex flex-column ">
                      {processConnections?.trainingValue.length > 0 ? (
                        <h6 className="fw-bold ms-4">Trainings: </h6>
                      ) : (
                        <h6 className="fw-bold ms-4 d-flex">
                          Trainings
                          <p className="mb-0 ms-2 not-applicable text-muted">
                            N/A
                          </p>
                        </h6>
                      )}
                      <div className="d-flex flex-row justify-content-center">
                        {processConnections?.trainingValue.length > 0 && (
                          <Carousel
                            nextIcon={nextArrow}
                            prevIcon={prevArrow}
                            interval={null}
                            wrap={false}
                            indicators={false}
                          >
                            {processConnections?.trainingValue.map(
                              (record, i) => (
                                <Carousel.Item key={i}>
                                  <Stack
                                    direction="horizontal"
                                    className="h-100  align-items-center"
                                    gap={2}
                                  >
                                    {record.map((elem, j) => {
                                      if (j < record.length - 1) {
                                        return (
                                          <>
                                            <ConnectionsComponent
                                              data={elem}
                                              key={j}
                                            />
                                            <div className="divider"></div>
                                          </>
                                        );
                                      } else {
                                        return (
                                          <ConnectionsComponent
                                            data={elem}
                                            key={j}
                                          />
                                        );
                                      }
                                    })}
                                  </Stack>
                                </Carousel.Item>
                              )
                            )}
                          </Carousel>
                        )}
                      </div>
                    </div>

                    <div className="mt-4 d-flex flex-column">
                      {processConnections?.appData.length > 0 ? (
                        <h6 className="fw-bold ms-4">Applications</h6>
                      ) : (
                        <h6 className="fw-bold ms-4 d-flex">
                          Applications
                          <p className="mb-0 ms-2 not-applicable text-muted">
                            N/A
                          </p>
                        </h6>
                      )}
                      <div className="d-flex flex-row justify-content-center">
                        {processConnections?.appData.length > 0 && (
                          <Carousel
                            nextIcon={nextArrow}
                            prevIcon={prevArrow}
                            interval={null}
                            wrap={false}
                            indicators={false}
                          >
                            {processConnections?.appData.map((record, i) => (
                              <Carousel.Item key={i}>
                                <Stack
                                  direction="horizontal"
                                  className="h-100 align-items-center"
                                  gap={2}
                                >
                                  {record.map((elem, j) => {
                                    if (j < record.length - 1) {
                                      return (
                                        <>
                                          <ConnectionsComponent
                                            data={elem}
                                            key={j}
                                          />
                                          <div className="divider"></div>
                                        </>
                                      );
                                    } else {
                                      return (
                                        <ConnectionsComponent
                                          data={elem}
                                          key={j}
                                        />
                                      );
                                    }
                                  })}
                                </Stack>
                              </Carousel.Item>
                            ))}
                          </Carousel>
                        )}
                      </div>
                    </div>

                    <div className="mt-4 d-flex flex-column">
                      {processConnections?.orgData.length > 0 > 0 ? (
                        <h6 className="fw-bold ms-4">Organization</h6>
                      ) : (
                        <h6 className="fw-bold ms-4 d-flex">
                          Organization
                          <p className="mb-0 ms-2 not-applicable text-muted">
                            N/A
                          </p>
                        </h6>
                      )}
                      <div className="d-flex flex-row justify-content-center">
                        {processConnections?.orgData.length > 0 && (
                          <Carousel
                            nextIcon={nextArrow}
                            prevIcon={prevArrow}
                            interval={null}
                            wrap={false}
                            indicators={false}
                          >
                            {processConnections?.orgData.map((record, i) => (
                              <Carousel.Item key={i}>
                                <Stack
                                  direction="horizontal"
                                  className="h-100  align-items-center"
                                  gap={2}
                                >
                                  {record.map((elem, j) => {
                                    if (j < record.length - 1) {
                                      return (
                                        <>
                                          <ConnectionsComponent
                                            data={elem}
                                            key={j}
                                          />
                                          <div className="divider"></div>
                                        </>
                                      );
                                    } else {
                                      return (
                                        <ConnectionsComponent
                                          data={elem}
                                          key={j}
                                        />
                                      );
                                    }
                                  })}
                                </Stack>
                              </Carousel.Item>
                            ))}
                          </Carousel>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showInfoModal ? (
        <MyVerticallyCenteredModal
          show={showInfoModal}
          onHide={() => setShowInfoModal(false)}
          data={pageData}
        />
      ) : null}
    </div>
  );
}
