import { useMsal } from "@azure/msal-react";
import "../NavbarComp/NavbarComp.scss";
import React, { useContext, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Modal, Button } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FiLogOut } from "react-icons/fi";
import { UserContext } from "../../contexts/UserContext";
import { NavLink, useNavigate } from "react-router-dom";
import ConfirmationBox from "../CustomTable/ConfirmationBox";
import { useClient } from "jsonapi-react";
import { profileIcon } from "../../utils/iconUtils";
import ImageSvgs from "../ImageSvgs";
import logo from "../../assets/images/se-header-logo.png";
import { adminIcon } from "../../utils/iconUtils";
import { getBaseUrl } from "../../config/environment";
import { Link } from "react-router-dom";
import empImg from "../../assets/images/EmployeesBar.svg";
import appImg from "../../assets/images/ApplicationsBar.svg";
import proImg from "../../assets/images/ProcessBar.svg";
import trainImg from "../../assets/images/TrainingsBar.svg";
import toolImg from "../../assets/images/ToolsBar.svg";
import handImg from "../../assets/images/HandbookBar.svg";
import orgImg from "../../assets/images/OrganizationBar.svg";
import commImg from "../../assets/images/CommunitiesBar.svg";
import { FiEdit } from "react-icons/fi";
import CustomizableHomeModal from "../CommonComponents/CustomizableHomeModal";
import { configureNewTokens } from "../../services/authService";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import CalendarComp from "../UltimoCalendar/CalendarComp";

const editIcon = (
  <span>
    <FiEdit />
  </span>
);
const logoutIcon = (
  <span>
    <FiLogOut />
  </span>
);

export default function NavbarComp({ setExpiryTime }) {
  const { instance } = useMsal();
  const { user } = useContext(UserContext);
  const initials = `${user.givenName.charAt(0)}${user.surname.charAt(0)}`;
  const client = useClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [url, setUrl] = useState();
  const navigate = useNavigate();
  const empId = JSON.parse(sessionStorage.getItem("userData")).employeeId;
  const [imgKey, setImgKey] = useState("emp");
  const [showEditModal, setShowEditModal] = useState(false);
  const profileImg = JSON.parse(
    sessionStorage.getItem("userData")
  ).profilePicture;

  const [initialTime, setInitialTime] = useState(
    Number(sessionStorage.getItem("expiryDuration"))
  );

  const [tokenExpiry, setTokenExpiry] = useState(
    sessionStorage.getItem("tokenExpiry")
  );

  const [timeLeft, setTimeLeft] = useState();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(2640000);

  useEffect(() => {
    const interval = setInterval(async () => {
      const tempTime = new Date().getTime();
      const elpTime = tempTime - parseInt(sessionStorage.getItem("loginTime"));

      if (elpTime >= elapsedTime && elapsedTime) {
        setModalShow(true);
        setElapsedTime(100000000000);
      }
    }, 1000);
    return () => clearInterval(interval);
  });

  function MyVerticallyCenteredModal(props) {
    const [confirmTime, setConfirmTime] = useState(null);
    useEffect(() => {
      setConfirmTime(10);
    }, []);

    useEffect(() => {
      if (confirmTime === 0) {
        setTimeLeft(null);
        props.onHide();
        instance.logoutRedirect();
      }
      if (!confirmTime) return;

      const intervalId = setInterval(() => {
        setConfirmTime(confirmTime - 1);
      }, 1000);

      return () => clearInterval(intervalId);
      // eslint-disable-next-line
    }, [confirmTime]);

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Session expiry warning!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex">
          <p className="mb-0">
            Your session is about to expire. Would you like to refresh the
            session?
          </p>
          <p className="ms-1 fw-bold mb-0">{`(${confirmTime})`}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="common-favbutton"
            className="me-2"
            onClick={async () => {
              props.onHide();
              setLoading(true);
              await setElapsedTime(2640000);
              await configureNewTokens(instance);
              await setInitialTime(
                Number(sessionStorage.getItem("expiryDuration"))
              );
              await setTokenExpiry(sessionStorage.getItem("tokenExpiry"));
              setLoading(false);
            }}>
            Confirm
          </Button>
          <Button
            id="common-favbutton"
            onClick={() => {
              setElapsedTime(2640000);
              props.onHide();
              instance.logoutRedirect();
            }}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const renderTime = ({ remainingTime }) => {
    setTimeLeft(Math.trunc(remainingTime / 60));

    return (
      <div className="timer">
        <div
          className={
            timeLeft && timeLeft <= 5
              ? "value fw-bold text-danger"
              : "value fw-bold"
          }>
          {Math.trunc(remainingTime / 60)}
        </div>
      </div>
    );
  };

  const handleClick = (event) => {
    event.preventDefault();
    if (sessionStorage.getItem("flagCheck") === "true") {
      setIsModalOpen(true);
    } else {
      navigate(event.currentTarget.attributes.href.nodeValue);
    }
    setUrl(event.currentTarget.attributes.href.nodeValue);
  };

  const changeOrder = async () => {
    const folderList = JSON.parse(sessionStorage.getItem("updatedList"));

    let counter = folderList.length;

    folderList.forEach((favorite) => {
      if (favorite.order !== counter) {
        // eslint-disable-next-line
        const resData = client.mutate(["user-favorites", favorite.id], {
          is_folder: favorite["is-folder"],
          order: counter,
        });
      }
      counter--;
    });

    setIsModalOpen(false);
    sessionStorage.setItem("flagCheck", false);
    navigate(url);
  };

  const onOkClick = () => {
    setIsModalOpen(false);
    sessionStorage.setItem("flagCheck", false);
    navigate(url);
  };

  const onCancelClick = () => {
    setIsModalOpen(false);
    sessionStorage.setItem("flagCheck", true);
  };

  const handleSignOut = () => {
    sessionStorage.clear();
    instance.logoutRedirect();
  };

  return (
    <>
      <Navbar id="main-navbar" className="color-nav" expand="lg">
        <Container>
          <Navbar.Brand
            href="https://siemensenergyag.sharepoint.com/sites/intranet-landing"
            target="_blank">
            <img
              src={logo}
              width="85"
              height="30"
              className="d-inline-block align-top"
              alt="SE Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav id="demo-nav" className="me-auto">
              <NavLink
                className="nav-hover nav-link"
                to="/"
                onClick={handleClick}
                end>
                Your Home
              </NavLink>

              <NavDropdown
                className="ms-3"
                title="FIN Landscape"
                id="basic-nav-dropdown"
                onClick={() => setImgKey("emp")}>
                <div className="d-flex">
                  <>
                    <img
                      src={
                        imgKey === "emp"
                          ? empImg
                          : imgKey === "apps"
                          ? appImg
                          : imgKey === "comm"
                          ? commImg
                          : imgKey === "hand"
                          ? handImg
                          : imgKey === "org"
                          ? orgImg
                          : imgKey === "process"
                          ? proImg
                          : imgKey === "tools"
                          ? toolImg
                          : imgKey === "train"
                          ? trainImg
                          : ""
                      }
                      className="p-2"
                      style={{ height: "16rem", width: "16rem" }}
                      alt="dropdown"
                    />
                  </>
                  <div className="d-flex flex-column">
                    <NavDropdown.Item
                      onClick={() => {
                        navigate("landscape");
                      }}
                      onMouseOver={() => {
                        setImgKey("emp");
                      }}>
                      Go to Landscape
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      onMouseOver={() => {
                        setImgKey("apps");
                      }}>
                      <Link
                        className="dropdown-items w-100"
                        style={{ textDecoration: "none", display: "block" }}
                        to={"/landscape/applications?orgId=0&appId=0"}
                        state={{ prevPath: window.location.href }}>
                        Applications
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onMouseOver={() => {
                        setImgKey("comm");
                      }}>
                      <Link
                        className="dropdown-items w-100"
                        style={{ textDecoration: "none", display: "block" }}
                        to={"/landscape/communities?page=0"}
                        state={{ prevPath: window.location.href }}>
                        Communities
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => {
                        window.open(
                          "https://siemensenergyag.sharepoint.com/sites/finance-center/Handbooks/Forms/AllItems.aspx",
                          "_blank"
                        );
                      }}
                      onMouseOver={() => {
                        setImgKey("hand");
                      }}>
                      Handbooks
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onMouseOver={() => {
                        setImgKey("org");
                      }}>
                      <Link
                        className="dropdown-items w-100"
                        style={{ textDecoration: "none", display: "block" }}
                        to={"/landscape/organization?id=0"}
                        state={{ prevPath: window.location.href }}>
                        Organizations
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onMouseOver={() => {
                        setImgKey("process");
                      }}>
                      <Link
                        className="dropdown-items w-100"
                        style={{ textDecoration: "none", display: "block" }}
                        to={"/landscape/process?process_id=0&view=cardView"}
                        state={{ prevPath: window.location.href }}>
                        Processes
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      onMouseOver={() => {
                        setImgKey("tools");
                      }}>
                      <Link
                        className="dropdown-items w-100"
                        style={{ textDecoration: "none", display: "block" }}
                        to={"/landscape/tools?layerId=1&toolId=0"}
                        state={{ prevPath: window.location.href }}>
                        Tools
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onMouseOver={() => {
                        setImgKey("train");
                      }}>
                      <Link
                        className="dropdown-items w-100"
                        style={{ textDecoration: "none", display: "block" }}
                        to={"/landscape/trainings?id=0"}
                        state={{ prevPath: window.location.href }}>
                        Trainings
                      </Link>
                    </NavDropdown.Item>
                  </div>
                </div>
              </NavDropdown>
            </Nav>
            <Nav className="justify-content-end">
              <Nav.Link className="nav-side ms-auto mx-2 ">
                <div className="d-flex flex-column">
                  <div className="fw-bold" style={{ color: "#8a00e5" }}>
                    {" "}
                    SE Finance Center
                  </div>
                  <div
                    style={{
                      fontStyle: "italic",
                      fontSize: "small",
                      color: "#969696",
                    }}>
                    {" "}
                    Integrate to Innovate
                  </div>
                </div>
              </Nav.Link>
              <div className="d-flex my-auto">
                <CalendarComp />
              </div>
              <a
                className="my-auto ms-3 "
                style={{
                  marginTop: "1rem",
                  marginRight: "1rem",
                }}
                href="https://forms.office.com/e/he9DtXAGpz"
                target="_blank"
                rel="noreferrer">
                <ImageSvgs name="supportIcon" />
              </a>
              {!loading ? (
                <>
                  {initialTime && tokenExpiry ? (
                    <div
                      className="mt-3"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={`Time left until session expires : ${timeLeft} minutes`}
                      style={{ zIndex: "2", cursor: "pointer" }}
                      onClick={async () => {
                        setLoading(true);
                        await configureNewTokens(instance);
                        await setInitialTime(
                          Number(sessionStorage.getItem("expiryDuration"))
                        );
                        await setTokenExpiry(
                          sessionStorage.getItem("tokenExpiry")
                        );
                        setLoading(false);
                      }}>
                      <CountdownCircleTimer
                        isPlaying
                        duration={initialTime}
                        initialRemainingTime={Math.trunc(
                          (new Date(tokenExpiry) - new Date()) / 1000
                        )}
                        size={30}
                        strokeWidth={2}
                        colors={timeLeft && timeLeft <= 5 ? "red" : "#8a00e5"}
                        onComplete={() => ({ shouldRepeat: false })}>
                        {renderTime}
                      </CountdownCircleTimer>
                    </div>
                  ) : null}
                </>
              ) : (
                <div id="session-loader" />
              )}
              <NavDropdown
                title={
                  profileImg === "null" || profileImg === "" ? (
                    <div id="circle" data-initials={initials}></div>
                  ) : (
                    <img
                      style={{
                        height: "2.3rem",
                        width: "2.3rem",
                        border: "1.5px solid black",
                        borderRadius: "7rem",
                      }}
                      src={`${getBaseUrl()}${profileImg}`}
                      alt="Profile"
                    />
                  )
                }
                id="collapsible-nav-dropdown"
                className="mt-1 navbar-dropdown"
                align={{ end: true }}>
                <NavDropdown.Item
                  onClick={() => {
                    let tempId = window.location.href.substring(
                      window.location.href.lastIndexOf("=") + 1
                    );
                    if (
                      window.location.href.includes("employee_relation") &&
                      tempId.toString() === empId.toString()
                    ) {
                    } else if (
                      window.location.href.includes("employee_relation") &&
                      tempId.toString() !== empId.toString()
                    ) {
                      window.location.assign(
                        `${window.location.href.substring(
                          0,
                          window.location.href.lastIndexOf("=") + 1
                        )}${empId}`
                      );
                    } else {
                      navigate(`/employee_relation?id=${empId}`);
                    }
                  }}>
                  <span className="fs-5 me-2">{profileIcon}</span>
                  My Profile
                </NavDropdown.Item>

                {sessionStorage.getItem("adminCheck") === "true" ? (
                  <NavDropdown.Item onClick={() => navigate("/manage_users")}>
                    <span className="fs-5 me-2">{adminIcon}</span>Admin Panel
                  </NavDropdown.Item>
                ) : null}

                <NavDropdown.Item onClick={() => setShowEditModal(true)}>
                  <span className="fs-5 me-2"> {editIcon}</span>
                  Edit Widgets
                </NavDropdown.Item>

                <NavDropdown.Item onClick={() => handleSignOut()}>
                  <span className="fs-5 me-2">{logoutIcon}</span>Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ConfirmationBox
        showDialog={isModalOpen}
        cancelNavigation={onCancelClick}
        confirmNavigation={onOkClick}
        saveNavigation={changeOrder}
      />
      {showEditModal ? (
        <CustomizableHomeModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          leftTitle={"Configure Widgets"}
        />
      ) : (
        ""
      )}

      {modalShow ? (
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          // confirmTime={confirmTime}
        />
      ) : null}
    </>
  );
}
